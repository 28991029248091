import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React, {useState, useContext} from "react";
import { API } from "../asset/conf";
import { Context } from "../App";

export default function ShipForm(props) {
    const { updateConnect } = useContext(Context)
    const [loading, setLoad] = useState(false)
    const [nom, setNom] = useState(props.ship?.nom??'')
    const [matricul, setMatricul] = useState(props.ship?.matricul??'')
    const [places, setPlaces] = useState(props.ship?.places??'')
    const [pmr, setPmr] = useState(props.ship?.places_pmr??'')

    function handleSubmitShip(event) {
        event.preventDefault()
        setLoad(true)

        fetch(API.concat(props.edit ? `/bateaux/update/${JSON.stringify(props.ship?._id)}`:'/bateaux'), {method:props.edit ? 'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({
                nom: nom,
                matricul: matricul,
                places: places,
                places_pmr: pmr
            })
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    props.addShip(json)
                    props.close()
                }
                if (response.status===403) {
                    alert('Ce bateau exist déjà')
                }
                setLoad(false)
            }
        })
    }

    return(
        <Box component='form' onSubmit={handleSubmitShip} sx={{padding:'20px'}}>
            <Typography variant="h3" textAlign='center' sx={{marginBottom:'15px'}}>Enregistrer un nouveau bateaux</Typography>
            <Stack direction='column' alignItems='center' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={nom}
                    onChange={(e)=> setNom(e.target.value)}
                    required label="Nom" type="text" autoFocus/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={matricul}
                    onChange={(e)=> setMatricul(e.target.value)}
                    required label="Matricule" type="text" autoFocus/>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'200px'}}
                    name='places' label="Places" type="number" required
                    InputProps={{ inputProps: { min: 0 } }}
                    value={places}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setPlaces(e.target.value)}}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'200px'}}
                    name='places_pmr' required label="Places PMR" type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={pmr}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setPmr(e.target.value)}}/>
                </Stack>
                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'end'}}
                color="primary"
                disabled={loading}
                >
                    {/*/part ship + rotation, ajouter une ligne d'entrée pour chaque rotation*/}
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                </Button>
            </Stack>
        </Box>
    )
}