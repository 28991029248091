import React, { createContext, useEffect, useState } from 'react';
import Login from './components/login';
import Home from './components/home';
import Header from './components/header';
import Footer from './components/footer';
import Reservation from './components/reservation';
import BilletsValid from './components/billets-valides';
import Finance from './components/finance';
import Organisation from './components/organisation';
import Client from './components/clients';
import Promo from './components/tarif-promo';
import TecScan from './components/tec-scan';
import Manager from './components/manager';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Listes from './components/listes';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    home_button: {
      fontFamily:'Inter',
      fontSize:16,
      fontWeight: 600,
    },
    h1: {
      fontFamily:'Inter',
      fontSize:28,
      fontWeight: 500,
      textDecoration:'underline',
      color: '#FF3C83',
      marginTop:'15px'
    },
    h2: {
      fontFamily: 'Inter',
      fontWeight:500,
      fontSize:22
    },
    h3: {
      fontFamily: 'Inter',
      fontWeight:500,
      fontSize:20
    },
    h4: {
      fontFamily:'Inter',
      fontWeight:600,
      fontSize:18
    },
    h5: {
      fontFamily:'Inter',
      fontWeight:500,
      fontSize:18
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize:16,
      fontWeight:600
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize:14,
      fontWeight:400
    },
    body1: {
      fontFamily: 'Inter',
      fontSize:14,
      fontWeight:400
    },
    info:{
      fontFamily:'Inter',
      fontSize:12,
      fontWeight:400
    },
  },
  components: {
    MuiButton: {
      styleOverrides:{
        root: {
          borderRadius:'2px',
          fontWeight:700
        }
      }
    },
    MuiTableRow:{styleOverrides:{ root:{
      border:'2px solid rgba(0,0,0,0.5)',
    }}},
  },
  palette: {
    primary: {
      main: '#3F88C5',
      light: '#A5DDFF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D9D9D9',
      contrastText: '#000',
    },
    rose: {
      main: '#FF3C83',
      contrastText: '#fff',
    },
    yellow: {
      main: '#EBE600',
      contrastText: '#000',
    },
    red: {
      main: '#FF0000'
    },
    vert: {
      main: '#4DA167',
      light: '#91EEAB',
      contrastText: '#000'
    },
    black:{
      main: '#000'
    },
    error:{
      main: '#590925'
    }
  },
});

const routes = [
  {path: '/', element: <Home />},
  {path: '/reservations', element: <Reservation />},
  {path: '/billets_valides', element: <BilletsValid />},
  {path: '/finances', element: <Finance />},
  {path: '/organisation', element: <Organisation />},
  {path: '/clients', element: <Client />},
  {path: '/promo', element: <Promo />},
  {path: '/tec_scan', element: <TecScan/>},
  {path: '/utilisateurs', element: <Manager/>},
  {path: '*', element: <Home />},
]

const unlogged_router = createBrowserRouter([
  {path:"/", element: <Login/>},
  {path:"*", element: <Login/>}
])

export const Context = createContext()

function App() {
  const [isConnect, setConnect] = useState(sessionStorage.getItem("token") ? true : false)
  const [router, setRouter] = useState(createBrowserRouter([{path: '*', element: <Home />}, {path: '/', element: <Home />}]))

  function updateConnect(newValue) {
    setConnect(newValue)
  }

  useEffect(()=>{
    if (isConnect) {
      setRouter(JSON.parse(sessionStorage.getItem('agent')).is_autority?
        createBrowserRouter([{path:'*', element: <Listes/>}, {path:'/', element: <Listes/>}, {path:'/listes', element: <Listes/>}]) :
        createBrowserRouter(JSON.parse(sessionStorage.getItem('agent'))?.access?.map(acces=> routes.find(route=>route.path.includes(acces))).concat({path: '/', element: <Home />}))
      )
    }
  },[isConnect])

  return (
    <Context.Provider value={{ isConnect, updateConnect }} >
      <ThemeProvider theme={theme}>
      <div style={{height:'100%', display:'flex', flexDirection:'column', fontFamily:'Inter'}}>
        <Header is_home={window.location.pathname==='/'}/>
        <RouterProvider router={isConnect ? router : unlogged_router}/> 
        <Footer/>
      </div>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;