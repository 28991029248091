import React, { useContext, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Typography, TableCell, IconButton, Dialog, Button, Stack } from "@mui/material";
import { Context } from "../App";
import { API } from "../asset/conf";
import SorTable from "./sortable";
import { Delete, Edit, InfoOutlined } from "@mui/icons-material";
import moment from "moment";
import ManagerForm from "./manager-form";

export default function Manager() {
    const { updateConnect } = useContext(Context)
    const [load, setLoad] = useState(true)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState()
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')

    const table =[
        {header:'Nom', sortable: true, sortBy:(el)=>(el.nom+' '+el.prenom).toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px', textTransform:'capitalize'}}>{row.nom+' '+row.prenom}</TableCell>},
        {header:'Identifiant', sortable: true, sortBy:(el)=>el.id, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.id}</TableCell>},
        {header:'Poste', sortable: true, sortBy:(el)=>el.poste, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px', textTransform:'capitalize'}}>{row.poste}</TableCell>},
        {header:"Lieu d'activité", sortable: true, sortBy:(el)=>el.where, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.where}</TableCell>},
        {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'90px', minWidth:'50px'}} style={{display:'flex', borderWidth:0, alignItems:'center', justifyContent:'end'}} >
            <IconButton aria-label="modifier" onClick={()=>{setUser(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>
            <IconButton aria-label="supprimer" onClick={()=>{setUser(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>
            <IconButton aria-label="informations complémentaires" onClick={()=>{setUser(row); manage_dialog(true, 'info')}}><InfoOutlined fontSize="small"/></IconButton>
        </TableCell>},
    ]

    async function manage_dialog(state, action) {
        setOpen(state)
        setAction(action)
        if (!state) {
            setUser()
        }
    }

    async function handleDelete() {
        fetch(API.concat(`/managers/${JSON.stringify({_id: user._id})}`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                  setUsers(old=>old.filter(usr=> usr._id!==user._id))
                  manage_dialog(false, '')
                }
                setLoad(false)
            }
        })
    }

    useEffect(()=>{
        function get_user() {
            fetch(API.concat('/managers'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                      const json = await response.json()
                      setUsers(json.filter(usr=>!usr.super_admin))
                    }
                    setLoad(false)
                }
            })
        }get_user()
    }, [updateConnect])

    return(
        <Box display='flex' flex={1} flexDirection='column' marginBottom='30px' paddingInline='30px'>
            <Typography variant="h1" align="center">Gestion des utilisateurs</Typography>
            <Button variant="contained" onClick={()=>manage_dialog(true, 'add')} sx={{alignSelf:'end'}}>Ajouter</Button>

            {users.length>0&&<SorTable data={users} table={table} rowsPerPageOptions={[5,10,50]} rowsPerPage={10}/>}

            <Dialog open={open} fullWidth maxWidth={['delete', 'info'].includes(action)?'xs':'sm'} onClose={()=>manage_dialog(false, '')}>
                {action==='add'&&<ManagerForm setUsers={usr=> setUsers(old=> old.concat(usr))} close={()=>manage_dialog(false, '')}/>}
                {action==='edit'&&<ManagerForm edit={true} user={user} setUsers={updated=> setUsers(old=> old.map(usr=>usr._id===user._id?updated:usr))} close={()=>manage_dialog(false, '')}/>}
                {action==='delete' && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Supprimer l'utilisateur <span style={{textTransform:'capitalize'}}>{user.nom+' '+user.prenom}</span></Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleDelete}>Supprimer</Button>
                    </Stack>
                </Box>}
                {action==='info' && user && <Box display='flex' flexDirection='column' marginTop='20px' padding='20px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px' textTransform='capitalize'>{(user.sex==='H'?'Mr ':'Mme ')+user.nom+' '+user.prenom}</Typography>
                    <Typography >Identifiant: {user.id}</Typography>
                    <Typography>Poste: {user.poste}</Typography>
                    <Typography>Lieu d'activité: {user.where}</Typography>
                    <Typography>Accès:</Typography>
                    {user.access.map(acces=><Typography marginLeft='20px'>- {acces.replace('_', ' ')}</Typography>)}
                </Box>}
            </Dialog>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}