import { Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import moment from "moment";

export default function AssignRotationForm(props) {
    const { updateConnect } = useContext(Context)
    const [loading, setLoad] = useState(true)
    const [duree, setDuree] = useState(120)
    const [duree2, setDuree2] = useState(120)
    const [ports, setPorts] = useState([])
    const [ships, setShips] = useState([])
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [hour, setHour] = useState('')
    const [min, setMin] = useState('')
    const [date, setDate] = useState(moment.utc(props.old_date).add(1,'day').format('yyyy-MM-DD'))
    const [ship, setShip] = useState('')
    const [from2, setFrom2] = useState('')
    const [to2, setTo2] = useState('')
    const [hour2, setHour2] = useState('')
    const [min2, setMin2] = useState('')
    const [ship2, setShip2] = useState('')

    function handleSubmit(event) {
        event.preventDefault()
        setLoad(true)

        fetch(API.concat(`/rotations/transfert/${moment.utc(props.old_date).format('yyyy-MM-DD')}`), {method:'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify([{
                from: from,
                to: to,
                heure_depart: [Number(hour), Number(min)],
                bateau: ship,
                date_depart:date,
                duree: Number(duree), // en min
                is_ponctual: true,
            }, {
                from: from2,
                to: to2,
                heure_depart: [Number(hour2), Number(min2)],
                bateau: ship2,
                date_depart:date,
                duree: Number(duree2), // en min
                is_ponctual: true,
            }])
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const rtn = await response.json()
                    props.addRotation(rtn, moment.utc(date).format('DD-MM-yyyy'))
                    props.close()
                }
                setLoad(false)
            }
        })
    }

    useEffect(()=>{
        async function get_ports() {
            fetch(API.concat('/ports'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setPorts(json)
                    }
                    setLoad(false)
                }
            })
        }
        async function get_ships() {
            fetch(API.concat('/bateaux'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setShips(json)
                    }
                    setLoad(false)
                }
            })
        }

        get_ports(); get_ships()
    }, [updateConnect])

    return(
        <Box component='form' onSubmit={handleSubmit} sx={{paddingInline:'20px', paddingBottom:'20px'}}>
            <Typography variant='subtitle1' textAlign='center' sx={{marginBottom:'10px'}}>Assigner une nouvelle date</Typography>
            <Stack spacing={1.5} alignItems='center'>
                <Typography variant='subtitle1' sx={{marginBottom:'10px'}}>ALLER</Typography>
                <Stack direction='row' alignItems='center' spacing={2} sx={{width:'100%'}}>
                    <TextField
                    sx={{minWidth:'200px'}} fullWidth color='primary' required type="date" label={"Date de la traversée"} 
                    InputLabelProps={{shrink: true}} value={date} onChange={(e)=> setDate(e.target.value)}/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='bateau' required label="Bateau" select
                    value={ship} onChange={(e)=> setShip(e.target.value)}>
                        {ships.map((shp) =>
                            <MenuItem key={shp._id} value={shp._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {shp.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{shp.matricul}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2} sx={{width:'100%'}}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='from' required label="Port de départ" select
                    value={from}
                    onChange={(e)=> setFrom(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='to' required label="Port d'arrivé" select
                    value={to}
                    onChange={(e)=> setTo(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                </Stack>
                <Stack direction='row' spacing={2} sx={{width:'100%'}}>
                    <Stack direction='row' spacing={1} style={{width:'50%'}}>
                        <TextField
                        fullWidth color='primary'
                        name='heure_depart' required label="Heure de départ" select
                        value={hour} onChange={(e)=> setHour(e.target.value)}>
                            {Array.from({length: 24}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                        fullWidth color='primary'
                        name='min_depart' required label="minute" select
                        value={min} onChange={(e)=> setMin(e.target.value)}>
                            {Array.from({length: 60}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                    </Stack>
                    <TextField
                    sx={{minWidth:'200px', width:'50%'}} margin="normal" color='primary'
                    name='duree' required label="Durée de la traversée (min)" type="number"
                    InputProps={{ inputProps: { min: 0, step:5 } }}
                    value={duree}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setDuree(e.target.value)}}/>
                </Stack>
            </Stack>
            <Stack spacing={1.5} alignItems='center' marginTop='20px'>
                <Typography variant='subtitle1' sx={{marginY:'10px'}}>RETOUR</Typography>
                <Stack direction='row' alignItems='center' spacing={2} sx={{width:'100%'}}>
                    <TextField
                    sx={{minWidth:'200px'}} fullWidth color='primary' required type="date" label={"Date de la traversée"} 
                    InputLabelProps={{shrink: true}} value={date} onChange={(e)=> setDate(e.target.value)}/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='bateau' required label="Bateau" select
                    value={ship2} onChange={(e)=> setShip2(e.target.value)}>
                        {ships.map((shp) =>
                            <MenuItem key={shp._id} value={shp._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {shp.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{shp.matricul}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2} sx={{width:'100%'}}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='from' required label="Port de départ" select
                    value={from2}
                    onChange={(e)=> setFrom2(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='to' required label="Port d'arrivé" select
                    value={to2}
                    onChange={(e)=> setTo2(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                    </TextField>
                </Stack>
                <Stack direction='row' spacing={2} sx={{width:'100%'}}>
                    <Stack direction='row' spacing={1} style={{width:'50%'}}>
                        <TextField
                        fullWidth color='primary'
                        name='heure_depart' required label="Heure de départ" select
                        value={hour2} onChange={(e)=> setHour2(e.target.value)}>
                            {Array.from({length: 24}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                        fullWidth color='primary'
                        name='min_depart' required label="minute" select
                        value={min2} onChange={(e)=> setMin2(e.target.value)}>
                            {Array.from({length: 60}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                    </Stack>
                    <TextField
                    sx={{minWidth:'200px', width:'50%'}} margin="normal" color='primary'
                    name='duree' required label="Durée de la traversée (min)" type="number"
                    InputProps={{ inputProps: { min: 0, step:5 } }}
                    value={duree2}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setDuree2(e.target.value)}}/>
                </Stack>

                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'end'}}
                color="primary"
                disabled={loading}
                >
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                </Button>
            </Stack>
        </Box>
    )
}