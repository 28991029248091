import * as React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination , TableRow, TableSortLabel, Paper} from '@mui/material';


function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

function stableSort(array, table, orderBy, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = orderBy >-1 ? comparator(table[orderBy]?.sortBy(a[0]), table[orderBy]?.sortBy(b[0])) : 0;
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, table } =    props;
    
    const createSortHandler = (property) => (event) => onRequestSort(event, property)

    return (
        <TableHead>
        <TableRow>
            {table.map((col, index) => (
            <TableCell
              key={index}
              align='center'
              sx={{fontWeight:'bold'}}
              sortDirection={orderBy === index ? order : false}
            >
                    {col.sortable? <TableSortLabel
                    active={orderBy === index}
                    direction={orderBy === index ? order : 'asc'}
                    onClick={createSortHandler(index)}
                    >
                        {col.header}
                    </TableSortLabel> : col.header}
            </TableCell>
            ))}
        </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.number.isRequired,
};

export default function SorTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage||10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(() => stableSort(props.data, props.table, orderBy, getComparator(order)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [props.data, props.table, order, orderBy, page, rowsPerPage]);

    return (
    <Box sx={{ width: '100%', marginTop:'30px' }}>
      <TableContainer>
          <Table
              sx={{ minWidth: 700 }}
              aria-labelledby="tableTitle"
          >
              <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              table={props.table}
              />
              <TableBody>
              {visibleRows.map((row, index) =>
                  <TableRow key={index} >
                      {props.table.map((col, index)=> col.row(row, index))}
                  </TableRow>
              )}
              </TableBody>
          </Table>
      </TableContainer>
      <TablePagination
      rowsPerPageOptions={props.rowsPerPageOptions}
      labelRowsPerPage='lignes par page'
      labelDisplayedRows={({ from, to, count, page }) => { return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`; }}
      component="div"
      count={props.data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, npage)=> setPage(npage)}
      onRowsPerPageChange={(event)=> {setRowsPerPage(parseInt(event.target.value, 10)); setPage(0);}}
      />
    </Box>
    );
}
