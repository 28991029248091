import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, Stack, TableCell, TextField, Typography, Dialog, InputAdornment } from '@mui/material';
import { Circle, Close, Edit, Search} from '@mui/icons-material';
import moment from 'moment';
import { Context } from '../App';
import { API } from '../asset/conf';
import SorTableCheckable from './sortable-checkable';
import pdfMake from "pdfmake/build/pdfmake";
import {list_psg_billetvalid} from "../asset/pdfmaker";
import pdfFonts from "pdfmake/build/vfs_fonts";
import BilletForm from './billet-form';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function BilletsValid() {
    const {updateConnect} = useContext(Context)

    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')
    const [loading, setLoad] = useState(true)
    const [billets, setBillets] = useState([])
    const [billet, setBillet] = useState()
    const [pdf, setPdf] = useState()
    
    const [selected, setSelect] = useState([])
    const [search_input, setSearch] = useState('')

    const table =[
        {header:'N° Billet', sortable: true, sortBy:(el)=>el.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.num}</TableCell>},
        {header:'Type', sortable: true, sortBy:(el)=>el.reservation?.where??'Agence', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.reservation?.where?? 'Agence'}</TableCell>},
        {header:'Passager', sortable: true, sortBy:(el)=>(el.passager.nom+' '+el.passager.prenom).toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.passager.nom+' '+row.passager.prenom}</TableCell>},
        {header:'Status paiement', sortable: true, sortBy:(el)=>el.reservation.pay_status, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.reservation.pay_status}</TableCell>},
        {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'120px'}} style={{display:'flex', borderWidth:0, alignItems:'center'}} >
            <IconButton aria-label="modifier" onClick={()=>{setBillet(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>
            {row.reservation.pay_status==='Acompte versé'&&<IconButton aria-label="acompte" onClick={()=>{setBillet(row); manage_dialog(true, 'pay status')}}><Circle color='primary' fontSize='small'/></IconButton>}
            {row.reservation.pay_status==='payé'&&<Circle color='vert' fontSize='small'/>}
        </TableCell>},
    ]

    async function manage_dialog(state, action) {
        setOpen(state)
        setAction(action)
        if (!state) {
            setBillet()
        }
    }

    async function get_pdf() {
        setLoad(true)
        const pdfDocGenerator = pdfMake.createPdf(list_psg_billetvalid(billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))), moment().format('yyyy-MM-DD'), 'valides'));
        pdfDocGenerator.getBlob((blob) => {
            setPdf(URL.createObjectURL(blob))
            manage_dialog(true, 'pdf')
            setLoad(false)
        })
    }

    function get_billets() {
        fetch(API.concat(`/billets/valides`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, blt, psg} = await response.json()
                    Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                        item.passager = psg.find(p=> p._id===item.passager)
                        item.reservation = rsv.find(r=> r.billets.includes(item._id))
                        resolve(item)
                    })})).then(results=>{
                        setBillets(results);
                        setLoad(false)
                    })
                }else setLoad(false)
            }
        })
    }

    function normalize(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
    }

    function get_searchable(blt) {
        return blt.num+' '+blt.reservation.num+' '+blt.reservation.num_facture+' '+blt.passager.nom+' '+blt.passager.prenom
    }

    useEffect(()=>{
       get_billets()
    }, [updateConnect])

    return (
      <Box sx={{display:'flex', flex:1, marginBottom:30, flexDirection:'column', paddingInline:'30px'}}>
        <Typography variant='h1' textAlign="center" sx={{marginBottom:'10px'}}>Gestion des Billets Valides</Typography>
        <Stack direction="row" spacing={3} sx={{alignItems:'center', alignSelf:'end'}}>
            {/* <Button disabled={selected.length<=0} onClick={()=>manage_dialog(true, 'affect')}>Affecter</Button> */}
            <Button disabled={billets.length<=0&&selected.length<=0} onClick={()=>get_pdf()}>Exporter</Button>
        </Stack>

        <TextField size="small" label="Rechercher un billet" sx={{width:'60%', minWidth:'300px'}}
        disabled={!billets?.length>0} value={search_input} onChange={(event)=> setSearch(event.target.value)}
        InputProps={{ endAdornment: <InputAdornment position="end"> <Search /></InputAdornment>}}/>

        {billets.length>0&&<SorTableCheckable data={billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input)))} table={table} selectItems={selected} setItems={setSelect} rowsPerPageOptions={[50,100,300]} rowsPerPage={100} />}
        
        <Dialog open={open} onClose={()=>manage_dialog(false, '')} fullScreen={action==='edit'} fullWidth maxWidth={action==='pdf'? 'md': 'sm'} sx={{minWidth:'500px'}}>
            {action!=='pdf'&&<IconButton onClick={()=> manage_dialog(false, '')} sx={{position:'absolute', right:'10px', top:'10px'}} ><Close/></IconButton>}
            {action==='edit' && <BilletForm close={()=> manage_dialog(false, '')} addRsv={edited=> setBillets(old=> {if(edited.rotation){return old.filter(bl=>bl._id!==edited._id)} else{old[old.findIndex(b=> b._id===edited._id)]=edited; return old;}})} billet={billet} />}
            {action==='pdf' && pdf && <embed src={pdf} width='100%' height='600px' type="application/pdf" />}
            {action==='pay status' && billet && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Paiement</Typography>
                <Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                    Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€
                </Typography>
                <Typography variant="subtitle1">Déjà payé : {billet.reservation?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
                <Typography variant="subtitle1" color='red'>Reste à payer: {billet.reservation?.rest_to_pay.toFixed(2)}€</Typography>
                {billet.reservation?.updates>1&&<Typography variant="subtitle1" style={{margin:'0 20px 20px 0'}}>
                    Frais de modification: {((billet?.reservation?.updates-1)*5).toFixed(2)}€
                </Typography>}
            </Box>}
            {action==='affect'&&<Box></Box>}
            </Dialog>

        <Backdrop open={loading} ><CircularProgress/></Backdrop>          
      </Box>
    );
}