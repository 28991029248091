import { AddCircleSharp, Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Checkbox, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import PortForm from "./port-form";
import moment from "moment";

export default function RotationForm(props) {
    const { updateConnect } = useContext(Context)
    const weekdays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    const [loading, setLoad] = useState(true)
    const [duree, setDuree] = useState(props.rotation?.duree??120)
    const [ports, setPorts] = useState([])
    const [from, setFrom] = useState(props.rotation?.from?._id??'')
    const [to, setTo] = useState(props.rotation?.to?._id??'')
    const [hour, setHour] = useState(props.rotation?.heure_depart[0]??'')
    const [min, setMin] = useState(props.rotation?.heure_depart[1]??'')
    const [days, setdays] = useState(props.rotation?.days??[])
    const [date, setDate] = useState(props.rotation?.date_depart? moment.utc(props.rotation.date_depart).format('yyyy-MM-DD'):'')
    const [type, setType] = useState(props.rotation?.is_ponctual? 'ponctuel':'permanent')
    const [is_journee, setJournee] = useState(false)
    const [open, setOpen] = useState(false)

    function handleSubmit(event) {
        event.preventDefault()
        setLoad(true)

        fetch(API.concat(props.edit?  `/rotations/update/${JSON.stringify(props.rotation?._id)}`:'/rotations'), {method:props.edit? 'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({
                from: from,
                to: to,
                heure_depart: [Number(hour), Number(min)],
                bateau: props.ship._id,
                days: days,
                date_depart:date,
                duree: Number(duree), // en secondes
                is_permanent: Boolean(type==='permanent'),
                is_ponctual: Boolean(type==='ponctuel'),
                is_journee: is_journee,
            })
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    json.from = ports.find(port=> port._id.toString()===json.from.toString())
                    json.to = ports.find(port=> port._id.toString()===json.to.toString())
                    props.addRotation(json)
                    props.close()
                }
                setLoad(false)
            }
        })
    }

    function addPort(port) {
        setPorts(old=> [...old, port])
    }

    useEffect(()=>{
        async function get_ports() {
            fetch(API.concat('/ports'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setPorts(json)
                    }
                    setLoad(false)
                }
            })
        }

        get_ports()
    }, [updateConnect])

    return(
        <Box component='form' onSubmit={handleSubmit} sx={{paddingInline:'20px', paddingBottom:'20px'}}>
            <Typography variant='subtitle1' textAlign='center' sx={{marginBottom:'10px'}}>Enregister une Rotation</Typography>
            <Stack spacing={1.5} alignItems='center'>
                <Stack direction='row' alignItems='center' spacing={2} sx={{width:'100%'}}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='from' required label="Port de départ" select
                    value={from}
                    onChange={(e)=> setFrom(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                        <Box style={{ display: 'flex', justifyContent: 'center', padding:0 }}>
                            <IconButton sx={{justifySelf:'center'}} onClick={()=> setOpen(true)}>
                                <AddCircleSharp color="primary" />
                            </IconButton>
                        </Box>
                    </TextField>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} color='primary'
                    name='to' required label="Port d'arrivé" select
                    value={to}
                    onChange={(e)=> setTo(e.target.value)}>
                        {ports.map((port) =>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>
                        )}
                        <Box style={{ display: 'flex', justifyContent: 'center', padding:0 }}>
                            <IconButton sx={{justifySelf:'center'}} onClick={()=> setOpen(true)}>
                                <AddCircleSharp color="primary" />
                            </IconButton>
                        </Box>
                    </TextField>
                </Stack>
                <Stack direction='row' spacing={2} sx={{width:'100%'}}>
                    <Stack direction='row' spacing={1} style={{width:'50%'}}>
                        <TextField
                        fullWidth color='primary'
                        name='heure_depart' required label="Heure de départ" select
                        value={hour} onChange={(e)=> setHour(e.target.value)}>
                            {Array.from({length: 24}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                        fullWidth color='primary'
                        name='min_depart' required label="minute" select
                        value={min} onChange={(e)=> setMin(e.target.value)}>
                            {Array.from({length: 60}, (v, i) => i).map((h, index) =>
                                <MenuItem key={index} value={h}>{h.toString().padStart(2, '0')}</MenuItem>
                            )}
                        </TextField>
                    </Stack>
                    <TextField
                    sx={{minWidth:'200px', width:'50%'}} margin="normal" color='primary'
                    name='duree' required label="Durée de la traversée (min)" type="number"
                    InputProps={{ inputProps: { min: 0, step:5 } }}
                    value={duree}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setDuree(e.target.value)}}/>
                </Stack>
                {type==='permanent' ? <FormControl sx={{ width:'60%', minWidth:'200px'}}>
                    <InputLabel>Jours de traversée</InputLabel>
                    <Select
                    fullWidth color='primary'
                    name='days' required label="Jours de traversée" multiple
                    value={days} onChange={(e)=> setdays(e.target.value)}>
                        {weekdays.map((day, index) =>
                            <MenuItem key={index} value={day} style={{textTransform:'capitalize'}}>{day}</MenuItem>
                        )}
                    </Select>
                </FormControl> :
                <TextField
                sx={{minWidth:'200px', width:'50%'}} margin="normal" color='primary'
                required
                type="date"
                label={"Date de la traversée"}
                InputLabelProps={{shrink: true}}
                value={date}
                onChange={(e)=> setDate(e.target.value)}/>
                }
                <RadioGroup row value={type} onChange={(e)=> {setType(e.target.value); setdays(); setDate(); setJournee(false)}}>
                    <FormControlLabel value="permanent" control={<Radio />} label="Permanente" />
                    <FormControlLabel value="ponctuel" control={<Radio />} label="Ponctuelle" />
                </RadioGroup>
                {type==='ponctuel'&&<FormControlLabel label='Journée shopping' control={<Checkbox checked={is_journee} onChange={()=>setJournee(old=>!old)}/>}/>}

                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'end'}}
                color="primary"
                disabled={loading}
                >
                    {/*/part ship + rotation, ajouter une ligne d'entrée pour chaque rotation*/}
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                </Button>
            </Stack>
            <Dialog open={open} sx={{minWidth:'350px'}}>
                <IconButton onClick={()=> setOpen(false)} sx={{alignSelf:'end'}} ><Close/></IconButton>
                <PortForm addPort={addPort} close={()=> setOpen(false)}/>
            </Dialog>
        </Box>
    )
}