import React, { useContext, useState } from "react";
import { Box, Button, TextField, CircularProgress, Avatar, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { Context } from "../App";
import { API } from "../asset/conf";
import {Buffer} from 'buffer';

export default function Login() {
    const { updateConnect } = useContext(Context)
    const [loading, setLoad] = useState(false)
    const [msg, setMsg] = useState()

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const mail = data.get('email')
        const mdp = data.get('password')

        if (mail.length > 0 && mdp.length > 0) {
            setLoad(true)
            fetch(API.concat('/login'), {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic '+Buffer.from(mail+':'+mdp).toString('base64'),
                },
            })
            .then(async(response)=> {
                if(response.status === 200)  {
                    const user = await response.json()
                    sessionStorage.setItem('agent', JSON.stringify(user))
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    updateConnect(response.headers.get('x-auth-token') ? true : false); 
                }else{
                    const text = await response.text()
                    setMsg(text)
                    setLoad(false)
                }
            })
        }
    };

    return(
        <div style={{display:'flex', flex:1, flexDirection:'column', padding:'10px 30px', justifyContent:'center', alignItems:'center'}}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <div style={{display:'flex', justifyContent:'center'}}><Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlined />
                </Avatar></div>
                <Typography variant="h5" align="center">
                    Connexion
                </Typography>
                <Typography variant="info" align="center" color={'error.main'}>{msg}</Typography>
                <TextField
                margin="normal"
                color='primary'
                required
                fullWidth
                id="email"
                label="Identifiant"
                name="email"
                autoComplete="email"
                autoFocus
                />
                <TextField
                margin="normal"
                color='primary'
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="primary"
                disabled={loading}
                >
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Connexion
                </Button>
            </Box>
        </div>
    )
}