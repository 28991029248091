import React, { useContext, useEffect, useState } from 'react';
import { Button, Paper, Stack, Typography, CircularProgress, Backdrop, Dialog, IconButton, Box, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material';
import Calendar from 'react-calendar';
import { Context, theme } from '../App';
// import 'react-calendar/dist/Calendar.css';
import { Close, ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { API } from '../asset/conf';
import { Stop } from '@mui/icons-material';
import ShipsTable from './ships';
import '../App.css';
import moment from 'moment';
import AssignRotationForm from './assign_rotation-form';

export default function Organisation() {
    const { updateConnect } = useContext(Context)
    const weekdays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    const date_modes = [
        {mode: 'permanent', color: 'primary'}, 
        {mode: 'exceptionnelle', color: 'vert'}, 
        {mode: 'annulé', color: 'rose'}
    ]
    const [loading, setLoad] = useState(true)
    const [open, setOpen] = useState(false)
    const [rotations, setRotations] = useState([])
    const [permanents, setPermanents] = useState([])
    const [ponctuals, setPonctuals] = useState([])
    const [canceleds, setCanceleds] = useState([])
    const [date, setDate] = useState()
    const [datemode, setDMode] = useState('permanent')
    const [cancel_for, setFor] = useState('')

    const tileClassName = ({ date, view }) => {
        // Afficher le style de tuile uniquement pour la vue du mois
        if (view === 'month') {
            //annulé
            if (canceleds.includes(moment.utc(date).format('DD-MM-yyyy'))) {
                return 'bg-pink';
            }
            // Ponctuel
            else if (ponctuals.includes(moment.utc(date).format('DD-MM-yyyy'))) {
                return 'bg-green';
            }
            // Permanent
            else if (permanents.includes(date.getDay())) {
                return 'bg-blue';
            }
        }
    }

    const handleClickDay = (value) => {
        if ((canceleds.concat(ponctuals).includes(moment.utc(value).format('DD-MM-yyyy'))||permanents.includes(value.getDay()))&&value.getTime()>=new Date(moment().format('yyyy-MM-DD')).getTime()) {
            setDate(value);
            setOpen(true);
        }
    };

    function handleCancel() {
        setLoad(true)
        fetch(API.concat(`/rotations/${moment.utc(date).format('yyyy-MM-DD')}/cancel`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: cancel_for
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setCanceleds(old=> old.concat(moment.utc(date).format('DD-MM-yyyy')))
                } 
                setOpen(false); setDate(); setLoad(false)
            }
        })
    }

    useEffect(()=>{

        function getRotations() {
            fetch(API.concat(`/rotations/${JSON.stringify({$or: [{is_permanent: true}, {is_ponctual: true}, {canceled: true}]})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setRotations(json)
                        setPermanents([...new Set(json.filter(rt=> rt.is_permanent).map(rt=> rt.days.map(day=>weekdays.indexOf(day))).flat())])
                        setPonctuals([...new Set(json.filter(rt=> rt.is_ponctual).map(rt=> moment.utc(rt.date_depart).format('DD-MM-yyyy')))])
                        setCanceleds([...new Set(json.filter(rt=> rt.canceled).map(rt=> moment.utc(rt.date_depart).format('DD-MM-yyyy')))])
                    }
                    setLoad(false)
                }
            })
        }

        getRotations()
    },[updateConnect])

    return (
      <Box sx={{display:'flex', flex:1, marginBottom:30, paddingInline:'30px', flexDirection:'column', alignItems:'center'}}>
        <Typography variant='h1'>Organisation</Typography>
        <Typography variant='subtitle2' textTransform='uppercase' sx={{marginTop:'40px'}} >gestion des dates de réservation</Typography>

        <Stack direction='row' spacing={10} justifyContent='center' alignItems='center' sx={{width:'100%', marginY:'20px'}}>
            {loading? <CircularProgress/> : 
            <Calendar 
            onClickDay={handleClickDay} 
            showDoubleView
            tileClassName={tileClassName}
            nextLabel={<ArrowForwardIosRounded/>}
            prevLabel={<ArrowBackIosRounded/>}
            next2Label={null}
            prev2Label={null} />}
            <Paper sx={{minWidth:'120px', minHeight:'150px', display:'flex', flexDirection:'column', padding:'30px 25px', borderRadius:0}}>
                <Typography variant='h3' align='center' sx={{marginBottom:'15px'}}>Légende</Typography>
                <Stack direction='column' spacing={2} sx={{justifyContent:'center', margin:'10px 0 20px 0'}}>
                    {date_modes.map((dmode, index)=>
                        <Button 
                        key={index} 
                        startIcon={<Stop color={dmode.color} style={{fontSize:datemode===dmode.mode? 36 : 30}} />}
                        sx={{fontFamily: 'Inter', fontSize:datemode===dmode.mode? 16 : 14, fontWeight:datemode===dmode.mode? 600:400, justifyContent:'flex-start', textTransform:'capitalize'}}
                        color={datemode===dmode.mode? dmode.color : 'black'}
                        onClick={()=> setDMode(dmode.mode)}>{dmode.mode}</Button>
                    )}
                </Stack>
            </Paper>
        </Stack>

        <Box sx={{backgroundColor:theme.palette.rose.main, height:'4px', width:'50%', borderRadius:'4px', marginTop:'10px'}}/>
        <ShipsTable/>

        <Dialog open={open} fullWidth maxWidth='xs' sx={{minWidth:'500px'}}>
            <IconButton onClick={()=> {setOpen(false); setDate()}} sx={{alignSelf:'end'}} ><Close/></IconButton>
            {canceleds.includes(moment.utc(date).format('DD-MM-yyyy')) ?
            <AssignRotationForm old_date={date} addRotation={(rtn, pdate)=>{setRotations(old=> old.concat(rtn)); setPonctuals(old=>old.concat(pdate))}} close={()=>{setOpen(false); setDate()}}/>
            
            :<><DialogTitle textAlign='center'>Annuler les rotations du {moment.utc(date).format('DD/MM/yyyy')} ?</DialogTitle>
            <DialogContent>
                <Typography>Annulation en raison de</Typography>
                <TextField multiline fullWidth required placeholder='exemple: conditions météorologiques défavorables, problèmes techniques, etc.' maxRows={3} rows={2} value={cancel_for} onChange={e=>setFor(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={()=>{setOpen(false); setDate()}}>non</Button>
                <Button variant='contained' onClick={handleCancel} disabled={cancel_for.length<4}>oui</Button>
            </DialogActions></>}
        </Dialog>

        <Backdrop open={loading} ><CircularProgress/></Backdrop>          
      </Box>
    );
}