import React, { useContext, useState } from 'react';
import { Backdrop, Button, CircularProgress, Stack, Typography, Box, Link } from '@mui/material';
import {EventRounded, AccountBalanceWalletRounded, SellRounded, GroupsRounded, CalendarMonthRounded, Style} from '@mui/icons-material';
import { API } from '../asset/conf';
import moment from 'moment';
import { Context } from '../App';

function Home() {
  const {updateConnect} = useContext(Context)
  const [load, setLoad] = useState(false)
  const access = JSON.parse(sessionStorage.getItem('agent'))?.access

  async function handleCloture() {
    setLoad(true)
    fetch(API.concat('/cloture_embarquement'), {method:'PATCH', headers:{
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },body: JSON.stringify({date: '2024-06-29', heur:moment().get('hour'), minute: moment().get('minutes')}) /*moment().format('yyyy-MM-DD')*/
    }).then(async(response)=>{
      const token = response.headers.get('x-auth-token')
      if (response.status === 401) {
        sessionStorage.clear()
        updateConnect(false)
      } else {
        if (token && token !== sessionStorage.getItem('token') && token !== '') {
          sessionStorage.setItem('token', response.headers.get('x-auth-token'))
        }
        setLoad(false)
      }
    })
  }

    return (
      <div style={{display:'flex', flex:1, marginBottom:30, flexDirection:'column', justifyContent:'center'}}>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginX='20px'>
          {access.includes('utilisateurs')&&<Link href='/utilisateurs'>Gestion des utilisateurs</Link>}
          {access.includes('tec_scan')&&<Stack direction='row' spacing={1} style={{alignSelf:'end', marginRight:'20px', marginBottom:'20px'}}>
            <Button variant='contained' href='/tec_scan'>TEC Scan</Button>
            <Button variant='contained' onClick={handleCloture} >Clôturer l'embarquement et envoyer</Button>
          </Stack>}
        </Box>
        
        <div style={{display:'flex', marginBottom:10, alignSelf:'center'}}>
          <Button variant='contained' disabled={!access.includes('reservations')} href='/reservations' style={{height:160, width:200, marginRight:40, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <EventRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>réservations</Typography>
          </Button>
          <Button variant='contained' disabled={!access.includes('finances')} href='/finances' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <AccountBalanceWalletRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>finances</Typography>
          </Button>
        </div>
        <div style={{display:'flex', marginBottom:10, alignSelf:'center'}}>
          <Button variant='contained' disabled={!access.includes('organisation')} href='/organisation' style={{height:160, width:200, marginRight:40, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <CalendarMonthRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>Organisation</Typography>
          </Button>
          <Button variant='contained' disabled={!access.includes('billets_valides')} href='/billets_valides' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <Style sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>Billets valides</Typography>
          </Button>
        </div>
        <div style={{display:'flex', justifyContent:'center'}}>
          <Button variant='contained' disabled={!access.includes('clients')} href='/clients' style={{height:160, width:200, marginRight:40, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <GroupsRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>clients</Typography>
          </Button>
          <Button variant='contained' disabled={!access.includes('promo')} href='/promo' style={{height:160, width:200, borderRadius:15, flexDirection:'column', justifyContent:'flex-end'}}>
            <SellRounded sx={{ fontSize: 80, marginBottom:'15px' }} />
            <Typography variant='home_button'>tarifs & promo</Typography>
          </Button>
        </div>
        <Backdrop open={load}><CircularProgress/></Backdrop>
      </div>
    );
  }
  
  export default Home;