import React, {useEffect, useState} from "react";
import { Typography, MenuItem, TextField, Button, IconButton, Box, Checkbox, Stack, FormControlLabel, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import moment from "moment";

export default function PayForm(props) {
    const [open, setOpen] = useState(false)
    const [pay, setpay] = useState()

    useEffect(()=>{}, [props.payments])

    return(
        <Box display='flex' flexDirection='column' marginX='15px'>
            <Box display='flex' justifyContent='space-between' marginBottom='10px' flexWrap='wrap'>
                {props.payments.map((pay, index) => <Box key={index} display='flex' flexDirection='column' justifyContent='center' width='47%' minWidth='400px' marginBottom='12px' padding='10px' border='1px solid black' borderRadius='5px' > 
                    {props.payments.length>1 && 
                    <IconButton aria-label="supprimer" style={{alignSelf:'end'}} onClick={()=> {setpay(pay); setOpen(true)}}>
                        <Delete color="red"/>
                    </IconButton>}
                    <TextField select sx={{minWidth:'300px', alignSelf:'center'}} required label="Mode de paiement"
                    value={pay.mode} onChange={(e)=> props.setPay(old => {const newArray = [...old]; newArray[index].mode = e.target.value; return newArray})}>
                        <MenuItem key={"especes"} value={"especes"}>
                            <Typography>Espèces</Typography> 
                        </MenuItem>
                        <MenuItem key={"cheque"} value={"cheque"}>
                            <Typography>Chèque</Typography> 
                        </MenuItem>
                        <MenuItem key={"virement"} value={"virement"}>
                            <Typography>Virement</Typography> 
                        </MenuItem>
                        <MenuItem key={"cb"} value={"cb"}>
                            <Typography>Carte Bancaire</Typography> 
                        </MenuItem>
                    </TextField>

                    <Typography sx={{marginY: '10px'}}>Détails*:</Typography>
                    <Stack direction='column' spacing={1}>
                        <Stack direction='row' spacing={1}>
                            <TextField type="date" value={moment.utc(pay.date).format('yyyy-MM-DD')} onChange={e=>props.setPay(old=>{const newArray = [...old]; newArray[index].date=e.target.value; return newArray})} sx={{minWidth:'150px'}}/>
                            <TextField fullWidth name="montant" label="Montant (en €)" inputProps={{step:"10", min:'0'}} type="number" sx={{minWidth:'150px'}}
                            value={pay.montant.toFixed(2)} onChange={(e)=>{if(!isNaN(Number(e.target.value)))props.setPay(old => {const newArray = [...old]; newArray[index].montant=Number(Number(e.target.value).toFixed(2)); return newArray})}}/>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            {pay.mode!=="especes"&&<TextField label={pay.mode==='cheque'?"N° chèque" :'Référence'} sx={{minWidth:'200px', width:'50%'}} value={pay.ref} onChange={e=>props.setPay(old=>{const newArray = [...old]; newArray[index].ref=e.target.value; return newArray})}/>}
                            <FormControlLabel control={<Checkbox checked={pay.is_acompte} onChange={()=>props.setPay(old=>{const newArray = [...old]; newArray[index].is_acompte= !old[index].is_acompte; return newArray})} color="primary" />} label="Acompte" />
                        </Stack>
                    </Stack>
                    
                </Box>)}  
            </Box>

            <Button aria-label="ajouter un paiement" variant="clear" sx={{alignSelf:'center', color:'primary.main', fontWeight:'bold', marginTop:'10px'}}
            onClick={()=> props.setPay(old => old.concat([{date: moment().format('yyyy-MM-DD'), montant: 0, is_acompte: false, ref: '', mode: ''}]))}>
                <Add sx={{margin:0}}/>
                <Typography>ajouter un paiment</Typography> 
            </Button>
            
            <Typography sx={{fontWeight:'bold'}}>Total payé: {props.payments.reduce((tt, current)=>tt+current.montant,0).toFixed(2)}€</Typography> 

            <Dialog open={open} maxWidth='xs'>
                <DialogTitle align="center">Êtes-vous sûr de vouloir supprimer le paiement {pay?.mode} de {pay?.montant?.toFixed(2)} du {moment.utc(pay?.date).format('DD/MM/yyyy')}?</DialogTitle>
                <DialogActions>
                    <Button variant="outlined" onClick={()=>{setpay();setOpen(false)}}>non</Button>
                    <Button variant="contained" onClick={()=> {props.setPay(old => old.slice(0, old.indexOf(pay)).concat(old.slice(old.indexOf(pay)+1))); setpay(); setOpen(false)}}>Oui</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}