import React, {useContext} from "react";
import { Paper, Typography, Link, Stack,IconButton } from "@mui/material"
import { ArrowBackOutlined, LogoutOutlined } from "@mui/icons-material";
import { Context } from "../App";

export default function Header(props) {
    const { updateConnect } = useContext(Context)

    return(
        <Paper elevation={5} style={{padding:'10px 20px 5px 20px', marginBottom:12, borderRadius:0, display:'flex', flexDirection:'row', justifyContent:'space-between',alignItems:'center'}}>
          {!props.is_home&&<Link href="/" underline="none" style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <ArrowBackOutlined color="black"/>
            <Typography marginLeft={2} fontSize={20} fontFamily={'Inter'} color='#000' >Retour</Typography>
          </Link>}
          <Stack direction='row' spacing={1} alignItems='center'>
            <Link href="/" underline="none" style={{}}>
              <Typography marginBottom={0} fontWeight='bold' fontSize={42} fontFamily={'Jaldi'} color='#000' >TEC</Typography>
              <Typography marginTop={-3.5} marginLeft={6.5} fontSize={21} fontFamily={'Inter'} color={'#3F88C5'} >Manager</Typography>
            </Link>
            <IconButton onClick={()=>{sessionStorage.clear(); updateConnect(false)}}><LogoutOutlined/></IconButton>
          </Stack>
        </Paper>
    )
}