import React, {useState, useContext} from 'react';
import {Box, Typography,Stack, TextField, Button, Backdrop, CircularProgress, FormControlLabel, RadioGroup, Radio} from '@mui/material';
import { API } from '../asset/conf';
import { Context } from '../App';
import {list_passagers, list_embarques} from "../asset/pdfmaker";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Listes() {
    const {updateConnect} = useContext(Context)
    const [load , setLoad] = useState(false)
    const [date, setdate] = useState('')
    const [is_embarque, setEmbarque] = useState(1)
    const [pdf, setPdf] = useState()

    async function search() {
        setLoad(true)
        fetch(API.concat(`/liste/${date}/${is_embarque}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {billets} = await response.json()
                    const pdfDocGenerator = pdfMake.createPdf(is_embarque===1 ? list_embarques(billets, date): list_passagers(billets, date, ''));
                    pdfDocGenerator.getBlob((blob) => {
                        setPdf(URL.createObjectURL(blob))
                        setLoad(false)
                    })
                }setLoad(false)
            }
        })
    }
    
    return(
        <Box display='flex' flex={1} flexDirection='column'>
            <Typography variant='h1' align='center' marginBottom={2}>Listes passagers</Typography>
            <Stack direction='row' alignItems='center' spacing={2} marginLeft={2}>
                <Typography>Passagers</Typography>
                <RadioGroup row value={is_embarque} onChange={(e)=> setEmbarque(e.target.value)} sx={{minWidth:'160px'}}>
                    <FormControlLabel value={0} control={<Radio />} label="réservés" />
                    <FormControlLabel value={1} control={<Radio />} label="embarqués" />
                </RadioGroup>
                <Typography>du</Typography>
                <TextField type='date' value={date} onChange={e=>setdate(e.target.value)}/>
                <Button variant='contained' disabled={date===''} onClick={search}>Chercher</Button>
            </Stack>
            {pdf&&<Box display='flex' flex={1}>
                <embed src={pdf} width='100%' height='100%' type="application/pdf" style={{marginTop:20}} />
            </Box>}
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}