import { Backdrop, Box, Button, CircularProgress, Dialog, Divider, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Scanner } from '@yudiel/react-qr-scanner';
import { API } from "../asset/conf";
import moment from "moment";
import { Context } from "../App";
import PayForm from "./pay-mode-form";
import { WarningTwoTone } from "@mui/icons-material";

export default function TecScan() {
    const {updateConnect} = useContext(Context)
    const [load, setLoad] = useState(true)
    const [scan, setScan] = useState(false)
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')
    const [rotation, setRotation] = useState()
    const [dispo_rtn, setDispoRtn] = useState([])
    const [billet, setBillet] = useState()
    const [payments, setPay] = useState([])
    const [embarques, setEmbarques] = useState(0)
    const [rest_to_pay, setTopay] = useState(0)

    function handleScan(data){
        const result = data[0]?.rawValue
        setScan(data[0]?.rawValue? false : true)
        setLoad(data[0]?.rawValue? true : false)
        fetch(API.concat(`/embarquement/billets/${result}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status===200) {
                    const {blt, psg, rsv} = await response.json()
                    blt.passager = psg; blt.reservation = rsv
                    setBillet(blt); setPay(rsv.payments)
                    setTopay(rsv.total+(rsv.updates>1?(rsv.updates-1)*5:0)-rsv.payments.reduce((tt, p)=>tt+p.montant,0))
                    manage_dialog(true, 'valide')
                }
            }
        }) 
    }

    async function handleValid() {
        setLoad(true)
        const scanned = !billet.isScaned
        fetch(API.concat(`/embarquement/billets/${billet._id}`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({scan: scanned, pay: payments, rtn: rotation._id})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status===200) {
                    setEmbarques(old=>old+(scanned?1:-1))
                }
                setLoad(false); manage_dialog(false, '')
            }
        }) 
    }

    async function manage_dialog(state, action) {
        setOpen(state)
        setAction(action)
        if (!state) {
            setBillet(); setPay([])
        }
    }

    async function handleRtn(rotation) {
        setEmbarques(rotation.embarques)
        fetch(API.concat(`/cloture_embarquement/${JSON.stringify(dispo_rtn.filter(rtn=> rtn._id!==rotation._id))}`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else if (token && token !== sessionStorage.getItem('token') && token !== '') {
                sessionStorage.setItem('token', response.headers.get('x-auth-token'))
            }
        }) 
    }

    useEffect(()=>{
        async function init() {
            const time = moment().get('hours')*60+moment().get('minutes')
            fetch(API.concat(`/embarquement/rotations/${moment().format('yyyy-MM-DD')}/${time}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const rtn = await response.json()
                        setDispoRtn(rtn)
                        if (rtn.length>1) {
                            manage_dialog(true, 'choose_rtn')
                        } else if(rtn.length>0){
                            setRotation(rtn[0])
                            setEmbarques(rtn[0]?.embarques)
                        }
                    }
                    setLoad(false)
                }
            }) 
        }

        init()
    }, [])

    return(
        <Box display='flex' flexDirection='column' sx={{flex:1, marginY:'30px'}}>
            <Stack direction='row' spacing={10} alignItems='center' alignSelf='end' marginRight='40px'>
                <Typography variant="subtitle1" color='primary'>Passagers embarqués: {embarques}</Typography>
                {rotation&&<Typography variant="subtitle1" color='red'>Places restantes: {rotation.places-embarques}</Typography>}
            </Stack>
            {!scan&&<Button variant="contained" sx={{alignSelf:'center', marginTop:'100px'}} disabled={!rotation} onClick={()=> setScan(true)}>Scanner</Button>}
            {scan&&<Scanner styles={{container:{width:'300px', height:'300px', alignSelf:'center', marginTop:'60px'}}} scanDelay={500} onScan={handleScan} />}
            
            <Dialog open={open} fullWidth maxWidth='sm'>
                {action==='choose_rtn'&&<Box display='flex' flexDirection='column' paddingBlock='30px' paddingInline='20px'>
                    <Typography variant="h3" marginBottom='20px'>Choisissez la rotation</Typography>
                    <Stack direction='column' spacing={2} alignItems='center'>
                        {dispo_rtn.map(rtn=>
                            <Button variant="outlined" key={rtn._id} onClick={()=>{setRotation(rtn); handleRtn(rtn);manage_dialog(false, '')}} style={{textTransform:'capitalize'}}>
                                {moment(rtn.heure_depart.join(':'), "HH:mm").format('HH:mm').replace(':','h')+' '+rtn.from.nom+' -> '+rtn.to.nom}
                            </Button>
                        )}
                    </Stack>
                </Box>}
                {action==='valide'&&<Box display='flex' flexDirection='column' paddingBlock='30px' paddingInline='20px'>
                    <Typography variant="h3" marginBottom='20px' textTransform='capitalize' align="center">Billet {billet.passager.categorie} N°{billet.num}</Typography>
                    <Typography variant="subtitle1" marginBottom='20px' textTransform='capitalize'>Passager: {billet.passager.nom+' '+billet.passager.prenom}</Typography>
                    {rest_to_pay>0&&<Stack direction='row' spacing={2} alignItems='center' marginY='20px' alignSelf='center'>
                        <WarningTwoTone color='red' sx={{ fontSize: 50 }}/>
                        <Typography variant="h2" color='red.main' textTransform='capitalize'>à payer: {rest_to_pay.toFixed(2)}€</Typography>
                    </Stack>}
                    {rest_to_pay>0&&<Divider variant="middle" sx={{marginX:'15%'}}/>}
                    {rest_to_pay>0&&<Typography variant="h4" align="center" marginY='20px'>Paiement</Typography>}
                    {rest_to_pay>0&&<PayForm payments={payments} setPay={setPay}/>}
                    <Stack direction='row' spacing={2} alignSelf='end'>
                        <Button variant="outlined" onClick={()=>{manage_dialog(false, '')}}>annuler</Button>
                        <Button variant="contained" onClick={()=>{handleValid();manage_dialog(false, '')}}>{billet.isScanned? 'déscanner':'valider'}</Button>
                    </Stack>
                </Box>}
            </Dialog>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}