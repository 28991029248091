import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, TableCell, CircularProgress, Backdrop, Dialog, IconButton, Box, DialogTitle, DialogActions} from '@mui/material';
import { Context } from '../App';
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import { API } from '../asset/conf';
import RotationForm from './rotation-form';
import SimpleTable from './simple-table';
import moment from 'moment';

export default function Rotations(props) {
    const { updateConnect } = useContext(Context)
    const [rotations, setRotations] = useState()
    const [rotation, setRotation] = useState()
    const [open, setOpen] = useState(false)
    const [d_action, SetAction] = useState('')
    const [loading, setLoad] = useState(true)
    const table =[
        {header:'Départ', row:(row, index)=> <TableCell key={index} align='center' sx={{width:'50%'}}>{row.from?.nom}</TableCell>},
        {header:'Destination', row:(row, index)=> <TableCell key={index} align='center' sx={{width:'50%'}}>{row.to?.nom}</TableCell>},
        {header:'Heure de départ', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px'}}>{`${row.heure_depart[0]}h${row.heure_depart[1]?.toString().padStart(2, '0')}`}</TableCell>},
        {header:'Jours/Date', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.is_permanent ? row.days.join(', ') : moment.utc(row.date_depart).format('DD/MM/yyyy')}</TableCell>},
        {header:'Durée du trajet(min)', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.duree}</TableCell>},
        {header:'Type', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.is_permanent ? 'Permanente': 'Ponctuelle'}</TableCell>},
        {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'120px'}}>
            <IconButton aria-label="modifier" onClick={()=>{setRotation(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>
            <IconButton aria-label="supprimer" onClick={()=>{setRotation(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>
        </TableCell>},
    ]

    async function manage_dialog(state, action) {
        setOpen(state)
        SetAction(action)
        if (!state) {
            setRotation()
        }
    }

    function handleDelete() {
        setLoad(true)
        fetch(API.concat(`/rotations/${JSON.stringify({_id: rotation._id})}`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setRotations(old => old.filter(shp=> shp._id !== rotation._id))
                    setLoad(false)
                    manage_dialog(false,'')
                }
            }
        })
    }

    useEffect(()=>{
        function get_rotations(ship) {
            fetch(API.concat('/ports'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const ports = await response.json()
                        fetch(API.concat(`/rotations/${JSON.stringify({$or: [{is_permanent: true}, {is_ponctual: true}], bateau: ship._id})}`), {method:'GET', headers:{
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                            }
                        }).then(async(response)=>{
                            const token = response.headers.get('x-auth-token')
                            if (response.status === 401) {
                                sessionStorage.clear()
                                updateConnect(false)
                            } else {
                                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                                }
                                if (response.status === 200) {
                                    const json = await response.json()
                                    const rtns = json.map(rtn=> {
                                        rtn.from = ports.find(port=> port._id.toString()===rtn.from.toString())
                                        rtn.to = ports.find(port=> port._id.toString()===rtn.to.toString())
                                        return rtn
                                    })
                                    setRotations(rtns)
                                    setLoad(false)
                                }
                            }
                        })
                    }else setLoad(false)
                }
            })
        }
        get_rotations(props.ship)
    }, [updateConnect, props.ship])

    return(
        <Box sx={{ display:'flex', flex:1, flexDirection:'column', alignItems:'center', paddingInline:'20px', paddingBottom:'15px'}}>
            <Typography variant='subtitle2' textTransform='uppercase'>Rotations de {props.ship.nom}</Typography>
            <Button variant='contained' sx={{alignSelf:'flex-end'}} onClick={()=> manage_dialog(true, 'add')} startIcon={<Add />}>Ajouter</Button>
            {rotations?.length>0 && <SimpleTable data={rotations} table={table} />}

            <Dialog fullWidth open={open} sx={{minWidth:'500px'}}>
                <IconButton onClick={()=> manage_dialog(false, '')} sx={{alignSelf:'end'}} ><Close/></IconButton>
                {d_action==='add'&&<RotationForm ship={props.ship} addRotation={(rotation)=> setRotations(old=> [...old, rotation])} close={()=> manage_dialog(false,'')}/>}
                {d_action==='edit'&&<RotationForm ship={props.ship} rotation={rotation} edit={true} addRotation={(edited)=> setRotations(old=> {
                    const nrotation=[...old]; nrotation[nrotation.indexOf(rotation)]=edited; return nrotation
                })} close={()=> manage_dialog(false,'')}/>}
                {d_action==='delete'&&<>
                <DialogTitle sx={{marginInline:'30px'}}>Supprimer la rotation</DialogTitle>
                <DialogActions sx={{marginBottom:'10px'}}>
                    <Button variant='contained' color="error" onClick={handleDelete}>Comfimer</Button>
                    <Button variant='contained' color='secondary' onClick={()=> manage_dialog(false,'')}>Annuler</Button>
                </DialogActions>
            </>}
            </Dialog>
            <Backdrop open={loading} ><CircularProgress/></Backdrop>
        </Box>
    )
}