import { Box, Button, CircularProgress, FormControlLabel, Stack, TextField, Typography, Checkbox } from "@mui/material";
import React, {useState, useContext} from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import moment from "moment";

export default function PromoForm(props) {
    const { updateConnect } = useContext(Context)
    const [loading, setLoad] = useState(false)
    const [libelle, setLibelle] = useState(props.promo?.libelle??'')
    const [is_percent, setPercent] = useState(props.promo?.is_in_percent??true)
    const [start, setStart] = useState(moment.utc(props.promo?.start_date??Date.now()).format('yyyy-MM-DD'))
    const [end, setEnd] = useState(props.promo?.end_date? moment.utc(props.promo.end_date).format('yyyy-MM-DD'):'')
    const [code, setcode] = useState(props.promo?.code??'')
    const [reduction, setreduction] = useState(props.promo?.reduction??10)

    function handleSubmit(event) {
        event.preventDefault()
        setLoad(true)

        fetch(API.concat(props.edit ? `/promos/update/${props.promo?._id}`:'/promos'), {method:props.edit ? 'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({
                libelle: libelle,
                start_date: new Date(start),
                end_date: new Date(end),
                code: code,
                reduction: reduction,
                is_in_percent: is_percent,
            })
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    props.addPromo(json)
                    props.close()
                }
                if (response.status===403) {
                    alert('Ce tarif exist déjà')
                }
                setLoad(false)
            }
        })
    }

    return(
        <Box component='form' onSubmit={handleSubmit} sx={{padding:'20px'}}>
            <Typography variant="h3" textAlign='center' sx={{marginBottom:'15px'}}>Enregistrer une nouvelle promotion</Typography>
            <Stack direction='column' alignItems='center' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={libelle}
                    onChange={(e)=> setLibelle(e.target.value)}
                    required label="Libellé" type="text" autoFocus/>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal" color='primary'
                    value={code}
                    onChange={(e)=> setcode(e.target.value)}
                    required label="Code promotionnel" type="text"/>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    label="Réduction" type="number" required
                    InputProps={{ inputProps: { min: 0, step:1 } }}
                    value={reduction}
                    onChange={(e)=>{if(/^\d+$/.test(e.target.value)|| e.target.value==='') setreduction(e.target.value)}}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}}
                    required label="Date début" type="date"
                    value={start}
                    onChange={(e)=>setStart(e.target.value)}/>
                    <TextField
                    fullWidth margin="normal" color='primary' sx={{minWidth:'90px'}} 
                    required label="Date fin" type="date" InputLabelProps={{shrink: true}}
                    value={end}
                    onChange={(e)=>setEnd(e.target.value)}/>
                </Stack>
                <FormControlLabel control={<Checkbox checked={is_percent} color="primary" onChange={()=>setPercent(old=>!old)} />} label="Promotion en pourcentage" />
                <Button
                type="submit"
                variant="contained"
                style={{alignSelf:'end'}}
                color="primary"
                disabled={loading}
                >
                {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                </Button>
            </Stack>
        </Box>
    )
}