import { Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, {useState, useContext} from "react";
import { API } from "../asset/conf";
import { Context } from "../App";

export default function PortForm(props) {
    const { updateConnect } = useContext(Context)
    const islands = ['Martinique', 'Sainte-Lucie']

    const [loading, setLoad] = useState(false)
    const [pays, setPays] = useState('')
    const [nom, setnom] = useState('')
    const [commune, setcommune] = useState('')

    function handleSubmit() {
        setLoad(true)
        fetch(API.concat('/ports'), {method:'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({
                nom: nom,
                commune: commune,
                pays: pays,
            })
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    props.addPort(json)
                    props.close()
                }
                setLoad(false)
            }
        })
    }

    // useEffect(()=>{
    //     //trouver une api qui renvoie toutes les communes d'un département/pays
    //     async function get_ports() {
    //         fetch(API.concat('/ports'), {method:'GET', headers:{
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    //         }}).then(async(response)=>{
    //             const token = response.headers.get('x-auth-token')
    //             if (response.status === 401) {
    //                 sessionStorage.clear()
    //                 updateConnect(false)
    //             } else {
    //                 if (token && token !== sessionStorage.getItem('token') && token !== '') {
    //                     sessionStorage.setItem('token', response.headers.get('x-auth-token'))
    //                 }
    //                 if (response.status === 200) {
    //                     const json = await response.json()
    //                     setCommunes(json)
    //                 }
    //                 setLoad(false)
    //             }
    //         })
    //     }

    //     get_ports()
    // }, [updateConnect])
   
    return(
        <Box padding='20px'>
            <Typography variant="h3" textAlign='center'>Enregistrer un nouveau port</Typography>
            <Box component='form' sx={{paddingTop:'7px'}}>
                <Stack direction='column' alignItems='center' spacing={2}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <TextField fullWidth sx={{minWidth:'200px'}} value={nom} margin="normal" color='primary'
                        required label="Nom" type="text" onChange={(e)=>setnom(e.target.value)} autoFocus/>
                        <TextField
                        fullWidth sx={{minWidth:'200px'}} margin="normal"  color='primary'
                        name='pays' required label="Île" select
                        value={pays}
                        onChange={(e)=> setPays(e.target.value)}>
                            {islands.map((item, index) =>
                                <MenuItem key={index} value={item} style={{textTransform:'uppercase'}}>
                                    {item}
                                </MenuItem>
                            )}
                        </TextField>
                    </Stack>
                    <TextField
                    fullWidth sx={{minWidth:'200px'}} margin="normal"  color='primary' type="text"
                    value={commune} onChange={(e)=>setcommune(e.target.value)} required label="Commune" disabled={pays===''} />
                    
                    <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{alignSelf:'end'}}
                    color="primary"
                    disabled={loading}
                    >
                        {loading && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } Enregistrer
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}