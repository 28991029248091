import React, { useContext, useState } from "react";
import { Backdrop, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Checkbox, TextField, Typography } from "@mui/material";
import { Context } from "../App";
import { API } from "../asset/conf";

export default function ManagerForm(props) {
    const {updateConnect} = useContext(Context)
    const [load, setLoad] = useState(false)
    const [id, setId] = useState(props.user?.id??'')
    const [mdp, setMdp] = useState('')
    const [access, setAccess] = useState(props.user?.access??[])
    const [nom, setNom] = useState(props.user?.nom??'')
    const [prenom, setPrenom] = useState(props.user?.prenom??'')
    const [sex, setSex] = useState(props.user?.sex??'H')
    const [poste, setPost] = useState(props.user?.poste??'')
    const [where, setWhere] = useState(props.user?.where??'')
    const [is_autority, setAutority] = useState(props.user?.is_autority??false)

    async function handleSubmit(e) {
        e.preventDefault()
        setLoad(true)
        const usr = is_autority? {id, mdp, access, nom, prenom,sex, is_autority} : {id, mdp, access, nom, prenom,sex,poste,where, is_autority}
        fetch(API.concat(props.edit?`/manager/${props.user._id}`:'/manager'), {method:props.edit?'PATCH':'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }, body:JSON.stringify(usr)}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                  const json = await response.json()
                  props.setUsers(json)
                  props.close()
                }
                setLoad(false)
            }
        })
    }

    return(
        <Stack direction='column' spacing={1} component='form' onSubmit={handleSubmit} margin='20px 15px'>
            <Typography variant="h3" align="center" marginBottom='20px'>{props.edit? 'Modifier':'Créer'} un utilisateur</Typography>
            <RadioGroup row value={sex} onChange={(e)=> setSex(e.target.value)} sx={{minWidth:'160px'}}>
                <FormControlLabel value={'H'} control={<Radio />} label="Homme" />
                <FormControlLabel value={'F'} control={<Radio />} label="Femme" />
            </RadioGroup>
            <Stack direction='row' spacing={2} alignItems='center'>
                <TextField required label='Nom' value={nom} onChange={e=>setNom(e.target.value)} fullWidth/>
                <TextField required label='Prénom' value={prenom} onChange={e=>setPrenom(e.target.value)} fullWidth/>
            </Stack>
            {!is_autority&&<Stack direction='row' spacing={2} alignItems='center'>
                <TextField required label='Poste' value={poste} onChange={e=> setPost(e.target.value)} fullWidth/>
                <TextField required label="Lieux d'activité" fullWidth select value={where} onChange={e=> setWhere(e.target.value)}>
                    <MenuItem value='Agence'>Agence</MenuItem>
                    <MenuItem value='Port'>Port</MenuItem>
                </TextField>
            </Stack>}
            <Stack direction='row' spacing={2} alignItems='center'>
                <TextField required label='Identifiant' value={id} onChange={e=>setId(e.target.value)} fullWidth/>
                <TextField required={!props.edit} label='Mot de passe' type="password" value={mdp} onChange={e=>setMdp(e.target.value)} fullWidth/>
            </Stack>
            {!is_autority&&<FormControl>
                <InputLabel>Accès</InputLabel>
                <Select required multiple fullWidth value={access} onChange={e=> setAccess(e.target.value)} label="Accès">
                    <MenuItem value='reservations'>Réservation</MenuItem>
                    <MenuItem value='finances'>Finances</MenuItem>
                    <MenuItem value='organisation'>Organisation</MenuItem>
                    <MenuItem value='billets_valides'>Billets Valides</MenuItem>
                    <MenuItem value='clients'>Clients</MenuItem>
                    <MenuItem value='promo'>Promos</MenuItem>
                    <MenuItem value='tec_scan'>TEC Scan</MenuItem>
                    <MenuItem value='utilisateurs'>Utilisateurs</MenuItem>
                    <MenuItem value='listes_passager'>Listes passagers</MenuItem>
                </Select>
            </FormControl>}
            <FormControlLabel control={<Checkbox checked={is_autority} onChange={e=>setAutority(old=>{if(!old){setAccess(['listes_passager'])}else{setAccess([])} return !old})}/>} label='Autorité'/>
            <Button type="submit" style={{marginTop:'15px'}}>Enregistrer</Button>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Stack>
    )
}