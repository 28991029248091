import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';


export default function SimpleTable(props) {
    return (
        <Table sx={{ minWidth: 650, marginTop:2, border:'4px solid black' }} aria-label="ships table">
            <TableHead sx={{borderBottom: '4px solid black'}}>
                <TableRow>
                    {props.table.map((col, index)=><TableCell key={index} align="center">{col.header}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
            {props.data.map((row) => (
                <TableRow key={row._id}>
                    {props.table.map((col, index)=> col.row(row, index))}
                </TableRow>
            ))}
            </TableBody>
        </Table>
    );
}