import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, ButtonGroup, Typography, Button, CircularProgress, ToggleButtonGroup, ToggleButton, Backdrop, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import moment from "moment/min/moment-with-locales.min.js";
import React, { useContext, useEffect, useState } from "react";
import { Context, theme } from "../App";
import { API } from "../asset/conf";


export default function RotatDays(props) {
    moment.locale('fr')
    const { updateConnect } = useContext(Context)
    const weekdays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    const [i, seti] = useState(0)
    const [select, setSelect] = useState(moment(props.date).format('yyyy-MM-DD'))
    const [dates, setdates] = useState([])
    const [price, setprice] = useState(props.passagers?.reduce((tt, current)=>tt+current.count*(props.tarifs.find(tf=>tf.isA_R===props.a_r&&current.label?.toLowerCase().includes(tf.categorie))?.prix??0),0))
    const [load, setload] = useState(true)
    const [saving, setSave] = useState(false)
    const [open, setOpen] = useState(false)
    const [select_rotation, setrotation] = useState('')

    function get_rotat(select) { return new Promise((resolve, reject)=>{
        const day = weekdays[moment(select).day()]
        if (props.rotations.find(rot=> rot.date_depart && moment.utc(rot.date_depart).format('yyyy-MM-DD')===select)) {
            resolve(props.rotations.filter(rot=> (!rot.canceled)&&rot.date_depart && moment.utc(rot.date_depart).format('yyyy-MM-DD')===select))
        } else if (props.rotations.find(rot=> rot.days.includes(day))) {
            resolve(props.rotations.filter(rot=> rot.days.includes(day)))
        } else resolve([])    
    })}

    function handleSave() {
        setSave(true)
        const rotation = props.rotations.find(rt=> rt._id===select_rotation)
        if (rotation.date_depart&&!rotation.is_permanent) {
            props.setdate(moment.utc(rotation.date_depart).format('yyyy-MM-DD'))
            props.setrotation(select_rotation)
            props.setJournee(rotation.is_journee)
            if(!props.is_edit_billet)props.next()
            setSave(false)
        } else {
            fetch(API.concat(`/rotations/permanents/${select}`), {method:'POST', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }, body: JSON.stringify(rotation)
            }).then(async response =>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        props.setdate(moment.utc(json.date_depart).format('yyyy-MM-DD'))
                        props.setrotation(json._id)
                        props.setJournee(json.is_journee)
                        if(!props.is_edit_billet)props.next()
                    }
                    setSave(false)
                }
            })
        }
    }

    useEffect(()=>{}, [props.rotation])

    useEffect(()=> {
        if(!props.is_edit_billet) setprice(props.passagers.reduce((tt, current)=>tt+current.count*(props.tarifs.find(tf=>tf.isA_R===props.a_r&&current.label?.toLowerCase().includes(tf.categorie))?.prix??0),0))},
    [props.passagers, props.tarifs, props.a_r])

    useEffect(()=>{
        async function launch() {
            Promise.all(props.dates.map(date=>{return new Promise((resolve, reject)=>{
                get_rotat(date).then(result=> resolve({date: date, rotations: result}))
            })})).then(result=>{
                setdates(result)
                setload(false)
            })
        }
        launch()
    }, [props.dates])

    return( load ?
        <CircularProgress/> :
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{marginTop:'30px'}}>
            <Box>
                <Box display='flex' justifyContent='center' alignItems='center' overflow='auto' >
                    {i>0&&<IconButton onClick={()=>seti(old=> old-7)}><ArrowBack/></IconButton>}
                    <ButtonGroup variant="text">{dates.slice(i, i+7).map((date, index)=>
                        <Button key={index} onClick={()=> setSelect(old=>{if(date.date!==old){setrotation('')} return date.date})} 
                        style={{textTransform:'none', minWidth:'120px',backgroundColor: date.date===select ? theme.palette.primary.main :'transparent', color: date.date===select ? 'white': theme.palette.primary.main }}>
                            {props.is_edit_billet? moment(date.date).format('ddd DD MMM'):<Typography>{moment(date.date).format('ddd DD MMM')}<br/>à partir de<br/>
                            <span style={{fontWeight:'bold'}}>{new Date(date.date)< new Date(moment().format('yyyy-MM-DD'))|| date.rotations.length<=0 ?'-': props.passagers.reduce((tt,cat)=>tt+(cat.label.includes('Nourrisson')?0:cat.count),0)>=10&&price<=0 ? 'éligible à un tarif groupe' :`${price.toFixed(2)}€`}</span></Typography>}
                        </Button>
                    )}</ButtonGroup>
                    {i<dates.length-7&&<IconButton onClick={()=>seti(old=> old+7)}><ArrowForward/></IconButton>}
                </Box>
                <Box minWidth='840px' display='flex' justifyContent='center' minHeight='150px' alignItems='center' sx={{backgroundColor:'primary.main'}}>
                    {new Date(select)< new Date(moment().format('yyyy-MM-DD'))|| dates.find(date=> date.date===select)?.rotations.length<=0 ?
                    <Typography>Aucune traversée pour ce jour</Typography> :
                    <Box display='flex' flex={1} padding={2} flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <ToggleButtonGroup
                        value={select_rotation}
                        exclusive
                        orientation="vertical"
                        onChange={(e, selected)=>{
                            if (selected!==null&&(props.is_edit_billet?selected.dispo_places===0:selected.dispo_places<props.passagers.reduce((tt, psg)=>tt+(psg.label.toLowerCase().includes('nourrisson')?0:psg.count),0))) {
                                setOpen(true)
                            } 
                            setrotation(selected)
                        }}
                        sx={{border:0}}
                        aria-label="traversée"
                        >{dates.find(date=> date.date===select)?.rotations.map(rotation=>
                            <ToggleButton key={rotation._id} value={rotation._id} sx={{border:0}}>
                                <Box display='flex' alignItems='center' padding={1} borderRadius='2px' sx={{backgroundColor:select_rotation===rotation._id ? 'primary.contrastText' : 'primary.light'}}>
                                    <Typography variant="subtitle1" marginRight='10px'>{moment(rotation.heure_depart.join(':'), 'HH:mm').format('HH:mm')}</Typography>
                                    <Typography textTransform='uppercase'>{props.ports.find(port=> port._id===rotation.from)?.nom}</Typography>
                                    <ArrowForward sx={{marginX:'60px'}}/>
                                    <Typography variant="subtitle1" marginRight='10px'>{moment(rotation.heure_depart.join(':'), 'HH:mm').add(rotation.duree, 'minutes').format("HH:mm")}</Typography>
                                    <Typography textTransform='uppercase'>{props.ports.find(port=> port._id===rotation.to)?.nom}</Typography>
                                </Box>
                            </ToggleButton>)}
                        </ToggleButtonGroup>
                        {!props.is_edit_billet&&<Box padding={2} borderRadius='2px' sx={{backgroundColor:'primary.light'}}>
                            <Typography textAlign='center' variant="subtitle1">{price.toFixed(2)}€</Typography>
                            {props.a_r && <Typography>Prix total Aller/Retour</Typography>}
                        </Box>}
                    </Box>}
                </Box>
            </Box>
            <Button onClick={handleSave} variant="contained" style={{alignSelf:'end', marginTop:20}} disabled={!select_rotation||select_rotation===''||!(props.rotation!==select_rotation)}>Enregistrer</Button>
            <Backdrop open={saving}><CircularProgress/></Backdrop>

            <Dialog open={open} onClose={()=>{setOpen(false); setrotation('')}}>
                <DialogTitle>Il ne reste plus que {select_rotation?.dispo_places} places sur cette rotation</DialogTitle>
                <DialogContent>Souhaitez-vous mettre {props.is_edit_billet? 'ce billet':'cette réservation'} sur liste d'attente?</DialogContent>
                <DialogActions>
                    <Button variant="clear" onClick={()=>{setOpen(false); setrotation('')}}>Annuler</Button>
                    <Button variant="contained" onClick={()=>setOpen(false)}>Mettre sur liste d'attente</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}