import moment from "moment";

export function list_embarques(billets, date) {
    let content = [...new Set(billets.map(blt=>blt.rotation?.from?._id))].map(pt=>{
        var tableau1 = [
            [
                {text: 'Nom'}, 
                {text: 'Prénom', alignment: 'center'},
                {text: 'Date de naissance', alignment: 'center'},
                {text: 'Lieu de naissance', alignment: 'center'},
                {text: 'Sexe', alignment: 'center'},
                {text: "Type pièce d'identité", alignment: 'center'},
                {text: "N° pièce ", alignment: 'center'},
                {text: "Date délivrance", alignment: 'center'},
                {text: "Date d'expiration", alignment: 'center'},
                {text: "Observation", alignment: 'center'},
            ],
        ].concat(billets.filter(blt=>blt.rotation?.from?._id===pt).map(blt=>[
                {text: blt.passager.nom?.toUpperCase()}, 
                {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
                {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.passager.birth_place, alignment: 'center'},
                {text: blt.passager.sex, alignment: 'center'},
                {text: blt.passager.identity_proof.type, alignment: 'center'},
                {text: blt.passager.identity_proof.num, alignment: 'center'},
                {text: moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy'), alignment: 'center'},
                {text: moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.reservation.commentaire??''+(blt.passager?.isPmr? (blt.reservation.commentaire!==''?'\n':'')+'PMR':''), alignment: 'center'},
            ])
        )

        var tableau2 = [
            [
                {text:'', border: [false, false]},
                {text: 'Adultes', style: 'tableHeader', alignment: 'center'},
                {text: 'Enfants', style: 'tableHeader', alignment: 'center'},
                {text: 'Nourrissons', style: 'tableHeader', alignment: 'center'},
                {text: 'TOTAL', style: 'tableHeader', alignment: 'center'},
            ],
            [
                {text: 'Total', style: 'tableHeader', alignment: 'center'}, 
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='adulte'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie?.includes('enfant')?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='nourrisson'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).length, alignment: 'center'}
            ],
        ]

        return([{text:`Au départ de ${billets.find(blt=>blt.rotation?.from?._id===pt)?.rotation?.from?.nom}\n\n`, style:'subheader'},
            {table: {
                widths: ['auto', 'auto', 70, 'auto', 'auto',60, 'auto',70, 70,'*'],
                headerRows: 1,
                body: tableau1
            }},
            {
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                headerRows: 1,
                body: tableau2
            }, margin: [0, 10, 0, 15]}
        ])
    }).flat()
    
    
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers embarqués le ${moment.utc(date).format('DD/MM/yyyy')}\n\n`, alignment:'center', style: 'header'},
        ].concat(content),
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 12}        
    }
    return dd
}

export function list_passagers(billets, date, filter) {
    let content = [...new Set(billets.map(blt=>blt.rotation?.from?._id))].map(pt=>{
        var tableau1 = [
            [
                {text: 'Nom'}, 
                {text: 'Prénom', alignment: 'center'},
                {text: 'Nationalité', alignment: 'center'},
                {text: 'Sexe', alignment: 'center'},
                {text: 'Date de naissance', alignment: 'center'},
                {text: 'Lieu de naissance', alignment: 'center'},
                {text: "Pièce", alignment: 'center'},
                {text: "N° pièce ", alignment: 'center'},
                {text: "Date délivrance", alignment: 'center'},
                {text: "Validité", alignment: 'center'},
                {text: "N° billet", alignment: 'center'},
                {text: "Observation", alignment: 'center'},
            ],
        ].concat(billets.filter(blt=>blt.rotation?.from?._id===pt).map(blt=>[
                {text: blt.passager.nom?.toUpperCase()}, 
                {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
                {text: blt.passager.nationality, alignment: 'center'},
                {text: blt.passager.sex, alignment: 'center'},
                {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.passager.birth_place, alignment: 'center'},
                {text: blt.passager.identity_proof.type, alignment: 'center'},
                {text: blt.passager.identity_proof.num, alignment: 'center'},
                {text: moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy'), alignment: 'center'},
                {text: moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy'), alignment: 'center'},
                {text: blt.num, alignment: 'center'},
                {text: blt.reservation.commentaire??''+(blt.passager?.isPmr? (blt.reservation.commentaire!==''?'\n':'')+'PMR':''), alignment: 'center'},
            ])
        )

        var tableau2 = [
            [
                {text:'', border: [false, false]},
                {text: 'Adultes', style: 'tableHeader', alignment: 'center'},
                {text: 'Enfants', style: 'tableHeader', alignment: 'center'},
                {text: 'Nourrissons', style: 'tableHeader', alignment: 'center'},
                {text: 'TOTAL', style: 'tableHeader', alignment: 'center'},
            ],
            [
                {text: 'Total', style: 'tableHeader', alignment: 'center'}, 
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='adulte'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie?.includes('enfant')?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).reduce((total,current)=>total+(current.passager?.categorie==='nourrisson'?1:0),0), alignment: 'center'},
                {text: billets.filter(blt=>blt.rotation?.from?._id===pt).length, alignment: 'center'}
            ],
        ]

        return([{text:`Au départ de ${billets.find(blt=>blt.rotation?.from?._id===pt)?.rotation?.from?.nom}\n\n`, style:'subheader'},
            {table: {
                widths: ['auto', 'auto', 'auto','auto', 55, 70 ,50, 'auto',55, 55,60,'*'],
                headerRows: 1,
                body: tableau1
            }},
            {
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                headerRows: 1,
                body: tableau2
            }, margin: [0, 10, 0, 15]}
        ])
    }).flat()
    
    
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers réservés ${filter} du ${moment.utc(date).format('DD/MM/yyyy')}\n\n`, alignment:'center', style: 'header'},
        ].concat(content),
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 10}        
    }
    return dd
}

export function list_psg_billetvalid(billets) {
    var tableau1 = [
        [
            {text: 'Nom'}, 
            {text: 'Prénom', alignment: 'center'},
            {text: 'Nationalité', alignment: 'center'},
            {text: 'Sexe', alignment: 'center'},
            {text: 'Date de naissance', alignment: 'center'},
            {text: 'Lieu de naissance', alignment: 'center'},
            {text: "Pièce", alignment: 'center'},
            {text: "N° pièce ", alignment: 'center'},
            {text: "Date délivrance", alignment: 'center'},
            {text: "Validité", alignment: 'center'},
            {text: "N° billet", alignment: 'center'},
            {text: "Observation", alignment: 'center'},
        ],
    ].concat(billets.map(blt=>[
        {text: blt.passager.nom?.toUpperCase()}, 
        {text: blt.passager.prenom?.at(0).toUpperCase()+blt.passager.prenom?.slice(1).toLowerCase(), alignment: 'center'},
        {text: blt.passager.nationality, alignment: 'center'},
        {text: blt.passager.sex, alignment: 'center'},
        {text: moment.utc(blt.passager.birth_date).format('DD/MM/yyyy'), alignment: 'center'},
        {text: blt.passager.birth_place, alignment: 'center'},
        {text: blt.passager.identity_proof.type, alignment: 'center'},
        {text: blt.passager.identity_proof.num, alignment: 'center'},
        {text: moment.utc(blt.passager.identity_proof.ddate).format('DD/MM/yyyy'), alignment: 'center'},
        {text: moment.utc(blt.passager.identity_proof.exp_date).format('DD/MM/yyyy'), alignment: 'center'},
        {text: blt.num, alignment: 'center'},
        {text: blt.reservation.commentaire+(blt.passager?.isPmr? (blt.reservation.commentaire!==''?'\n':'')+'PMR':''), alignment: 'center'},
    ]))
    
    var dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
          // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 30, 30, 30, 30 ],
        content: [
            {text: `Liste passagers billets valides\n\n`, alignment:'center', style: 'header'},
            {table: {
                widths: ['auto', 'auto', 'auto','auto', 55, 70 ,50, 'auto',55, 55,60,'*'],
                headerRows: 1,
                body: tableau1
            }},
        ],
        styles:{
            header_footer: {fontSize:8, bold: true},
            header: {fontSize:20, bold: true},
            light_header: {fontSize:18},
            subheader: {fontSize:16, bold: true},
            tableHeader: {bold: true},
            tableTotal: {bold: true, color: '#fff'}
        },
        defaultStyle:{fontSize: 10}        
    }
    return dd
}
