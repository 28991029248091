import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, MenuItem, Stack, TableCell, TextField, Typography, Dialog, Checkbox, InputAdornment, Menu, Divider } from '@mui/material';
import { Circle, Close, Delete, Edit, InfoOutlined, MoneyOff, Search, SwapHoriz, WarningTwoTone} from '@mui/icons-material';
import moment from 'moment';
import { Context } from '../App';
import { API } from '../asset/conf';
import SorTableCheckable from './sortable-checkable';
import ReservationForm from './reservation-form';
import pdfMake from "pdfmake/build/pdfmake";
import {list_passagers, list_embarques} from "../asset/pdfmaker";
import pdfFonts from "pdfmake/build/vfs_fonts";
import BilletForm from './billet-form';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Reservation() {
    const {updateConnect} = useContext(Context)
    const [ports, setPorts] = useState([])
    const [rotations, setRotations] = useState([])

    const [date, setDate] = useState(moment().format('yyyy-MM-DD'))
    const [port, setport] = useState('')
    const [portb, setportb] = useState('')
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')
    const [loading, setLoad] = useState(true)
    const [billets, setBillets] = useState([])
    const [billet, setBillet] = useState()
    const [pdf, setPdf] = useState()
    
    const [selected, setSelect] = useState([])
    const [search_input, setSearch] = useState('')
    const [filter,setFilter] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const open_menu = Boolean(anchorEl);

    const table =[
        {header:'N° Billet', sortable: true, sortBy:(el)=>el.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.num}</TableCell>},
        {header:'Type', sortable: true, sortBy:(el)=>el.reservation?.where??'Agence', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.reservation?.where?? 'Agence'}</TableCell>},
        {header:'Date départ', sortable: true, sortBy:(el)=>new Date(el.rotation?.date_depart).getTime(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px'}}>{moment.utc(row.rotation?.date_depart).format('DD/MM/yyyy')}</TableCell>},
        {header:'Passager', sortable: true, sortBy:(el)=>(el.passager.nom+' '+el.passager.prenom).toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.passager.nom+' '+row.passager.prenom}</TableCell>},
        {header:'Status paiement', sortable: true, sortBy:(el)=>el.reservation.pay_status, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.reservation.pay_status}</TableCell>},
        {header:'Validé', sortable: true, sortBy:(el)=>el.isScanned, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px'}}><Checkbox checked={row.isScanned} disabled={!row.isValide} onChange={()=>{setBillet(row); manage_dialog(true, 'valid')}}/></TableCell>},
        {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'120px'}} style={{display:'flex', borderWidth:0, alignItems:'center', justifyContent:'end'}}>
            {row.reservation.pay_status!=="remboursée"&&<IconButton aria-label="modifier" onClick={()=>{setBillet(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>}
            {["en attente"].includes(row.reservation?.pay_status)&&<IconButton aria-label="supprimer" onClick={()=>{setBillet(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>}
            <IconButton aria-label="informations complémentaires" onClick={()=>{setBillet(row); get_info(row)}}><InfoOutlined fontSize="small"/></IconButton>
            {row.reservation.pay_status==='en attente'&&<IconButton aria-label="non payé" onClick={()=>{setBillet(row); manage_dialog(true, 'pay status')}}><WarningTwoTone color='yellow' fontSize='small'/></IconButton>}
            {row.reservation.pay_status==='Acompte versé'&&<IconButton aria-label="acompte" onClick={()=>{setBillet(row); manage_dialog(true, 'pay status')}}><Circle color='primary' fontSize='small'/></IconButton>}
            {row.reservation.pay_status==='payé'&&<Circle color='vert' fontSize='small' sx={{marginX:'7px'}}/>}
            <IconButton disabled={['en attente', 'remboursée'].includes(row.reservation.pay_status)} onClick={()=>{setBillet(row); manage_dialog(true, 'refund')}}><MoneyOff color={row.reservation.pay_status==='remboursée'?'red':''} fontSize='small' /></IconButton>
            {row.rotation.waiting_list.includes(row._id)&&<IconButton onClick={()=>{setBillet(row); manage_dialog(true, 'bascule')}}><SwapHoriz fontSize='small' sx={{marginX:'7px'}}/></IconButton>}
        </TableCell>},
    ]

    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function manage_dialog(state, action) {
        setOpen(state)
        setAction(action)
        if (!state) {
            setBillet()
        }
    }

    async function get_info(row) {
        console.log(row); setLoad(true)
        fetch(API.concat(`/reservations/info/${JSON.stringify({passagers: row.reservation.passagers, manager: row.reservation.by})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {psg, mng} = await response.json()
                    row.reservation.passagers = psg.map(psg=>psg.nom+' '+psg.prenom)
                    row.reservation.by = mng.nom+' '+mng.prenom+' ('+mng.poste+')'
                    setBillet(row)
                    manage_dialog(true, 'info')
                    setLoad(false)
                }
            }
        }) 
    }

    async function get_embarques() {
        setLoad(true)
        const pdfDocGenerator = pdfMake.createPdf(list_embarques(billets.filter(blt=>blt.isScanned), date));
        pdfDocGenerator.getBlob((blob) => {
            setPdf(URL.createObjectURL(blob))
            manage_dialog(true, 'pdf')
            setLoad(false)
        })
    }

    async function get_pdf() {
        setLoad(true)
        const pdfDocGenerator = pdfMake.createPdf(list_passagers(billets.filter(blt=>filtrer(blt)), date, filter));
        pdfDocGenerator.getBlob((blob) => {
            setPdf(URL.createObjectURL(blob))
            manage_dialog(true, 'pdf')
            setLoad(false)
        })
    }

    async function handleRefund() {
        setLoad(true)
        fetch(API.concat(`/reservations/${billet.reservation._id}/refund`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    setBillets(old=>old.map(bl=>{
                        if (bl.reservation._id.toString()===json._id.toString()) {
                            bl.reservation = json
                        } return bl
                    }))
                    manage_dialog(false, '')
                }
            }
            setLoad(false)
        })
    }

    async function handleSwap() {
        setLoad(true)
        fetch(API.concat(`/reservations/${billet.reservation._id}/rotation/${billet.rotation._id}/bascule`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    manage_dialog(false, '')
                    handleSetport(date, port, portb)
                }else setLoad(false)
            }
        })
    }

    async function handleDelete() {
        setLoad(true)
        fetch(API.concat(`/reservations/nopayed/${JSON.stringify([billet.reservation._id])}`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setBillets(old=>old.filter(blt=>blt.reservation._id!==billet.reservation._id))
                    manage_dialog(false, '')
                }
            }
            setLoad(false)
        })
    }

    async function handleDeleteMany() {
        const todel = billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt))
        setLoad(true)
        fetch(API.concat(`/reservations/nopayed/${JSON.stringify([...new Set(todel.map(blt=> blt.reservation._id))])}`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setBillets(old=>old.filter(blt=>!todel.includes(blt)))
                    manage_dialog(false, '')
                }
            }
            setLoad(false)
        })
    }

    async function handleSetport(date, porta, portb) {
        setLoad(true)
        const request = {date_depart:new Date(date)}
        if(porta!=='') request.from = porta
        if(portb!=='') request.to = portb
        fetch(API.concat(`/reservations/rotations/${JSON.stringify(request)}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, rtn, blt, psg} = await response.json()
                    Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                        item.from = ports.find(p=> p._id===item?.from)
                        item.to = ports.find(p=> p._id===item?.to)
                        resolve(item)
                    })})).then(rotations=>{
                        setRotations(rotations)
                        Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                            item.passager = psg.find(p=> p._id===item.passager)
                            item.rotation = rotations.find(r=>r._id===item.rotation)
                            item.reservation = rsv.find(r=> r.billets.includes(item._id))
                            resolve(item)
                        })})).then(results=>{
                            setBillets(results);
                            setLoad(false)
                        })
                    })
                } 
                setLoad(false)
            }
        })  
    }

    function get_reservations(ports, date) {
        fetch(API.concat(`/reservations/rotations/${JSON.stringify({date_depart: new Date(date)})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, rtn, blt, psg} = await response.json()
                    Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                        item.from = ports.find(p=> p._id===item?.from)
                        item.to = ports.find(p=> p._id===item?.to)
                        resolve(item)
                    })})).then(rotations=>{
                        setRotations(rotations)
                        Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                            item.passager = psg.find(p=> p._id===item.passager)
                            item.rotation = rotations.find(r=>r._id===item.rotation)
                            item.reservation = rsv.find(r=> r.billets.includes(item._id))
                            resolve(item)
                        })})).then(results=>{
                            setBillets(results);
                            setLoad(false)
                        })
                    })
                }else setLoad(false)
            }
        })
    }

    function handleScan() {
        setLoad(true)
        fetch(API.concat(`/billets/${billet._id}/update`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({isScanned: !billet.isScanned})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json() 
                    billet.isScanned = json.isScanned
                    manage_dialog(false, '')
                }
                setLoad(false)
            }
        })
    }

    function handleTransition() {
        setLoad(true)
        fetch(API.concat(`/billets/${JSON.stringify({_id: {$in: selected}})}`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({$unset: {rotation: ''}})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) { 
                    setBillets(old=> old.filter(blt=> !selected.includes(blt._id)))
                    setSelect([])
                    manage_dialog(false, '')
                }
                setLoad(false)
            }
        })
    }

    function normalize(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
    }

    function get_searchable(blt) {
        return blt.num+' '+blt.reservation.num+' '+blt.reservation.num_facture+' '+blt.passager.nom+' '+blt.passager.prenom
    }

    function filtrer(blt) {
        if(filter!=='') {
            switch (filter) {
                case 'payé':
                    return !["en attente", "remboursée"].includes(blt.reservation.pay_status)
                case 'transition':
                    return blt.rotation.waiting_list.includes(blt._id)
                default:
                    return ["en attente", "remboursée"].includes(blt.reservation.pay_status);
            }
        } else return true
    }

    useEffect(()=>{
        function get_ports() {return new Promise((resolve, reject) => {
            fetch(API.concat('/ports'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setPorts(json)
                        resolve(json)
                    }
                }
            })  
        })}

        get_ports().then(results=> get_reservations(results, date))
    }, [updateConnect])

    return (
      <Box sx={{display:'flex', flex:1, marginBottom:30, flexDirection:'column', paddingInline:'30px'}}>
        <Typography variant='h1' textAlign="center" sx={{marginBottom:'10px'}}>Gestion des réservations</Typography>
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Stack direction="row" spacing={1} sx={{alignItems:'center'}}>
                <Typography variant='subtitle1'>Date:</Typography>
                <TextField
                type='date'
                value={date}
                onChange={(e)=>{setDate(e.target.value); if(port!==''){handleSetport(e.target.value, port)} else get_reservations(ports, e.target.value)}}
                size='small'/>
                <TextField select label='au départ de...' InputLabelProps={{style:{marginRight:'5px'}}} value={port}
                onChange={(e)=>{setport(e.target.value);handleSetport(date, e.target.value)}} size='small' sx={{minWidth:'150px'}}>
                    {ports.map((port)=>
                        <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                            <Typography textTransform='capitalize'>
                                {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                            </Typography>
                        </MenuItem>
                    )}
                    <MenuItem value=''>Indifférent</MenuItem>
                </TextField>
                <TextField select label='vers...' InputLabelProps={{style:{marginRight:'5px'}}} value={portb}
                onChange={(e)=>{setportb(e.target.value);handleSetport(date, port, e.target.value)}} size='small' sx={{minWidth:'150px'}}>
                    {ports.map((portb)=>
                        <MenuItem key={portb._id} disabled={portb._id===port} value={portb._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                            <Typography textTransform='capitalize'>
                                {portb.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{portb.pays}</span>
                            </Typography>
                        </MenuItem>
                    )}
                    <MenuItem value=''>Indifférent</MenuItem>
                </TextField>
            </Stack>

            <Stack direction="row" spacing={3} sx={{alignItems:'center'}}>
                <Button
                id="basic-button"
                aria-controls={open_menu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_menu ? 'true' : undefined}
                onClick={handleClick}
                >
                    Actions
                </Button>
                <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open_menu}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                >
                    <MenuItem onClick={()=>{manage_dialog(true, 'add'); handleClose()}}>Ajouter</MenuItem>
                    <MenuItem onClick={()=>{manage_dialog(true, 'transition'); setSelect(old=>old.length>0?
                        old.filter(id=>{const blt = billets.find(blt=>blt._id===id); return blt.isValide&&!blt.isScanned})
                        :billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)&&blt.isValide&&!blt.isScanned).map(blt=>blt._id)); handleClose()}}>Transition</MenuItem>
                    {billets.filter(blt=>filtrer(blt)).length>0&&<MenuItem onClick={()=>{get_pdf(); handleClose()}}>Exporter</MenuItem>}
                    {filter==='non payé'&&billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)).length>0&&<MenuItem onClick={()=>{manage_dialog(true, 'delete many'); handleClose()}}>Supprimer</MenuItem>}
                </Menu>
                <Button variant='contained' disabled={billets.length<=0} onClick={get_embarques}>Embarqués</Button>
            </Stack>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='20px'>
            <TextField
            size="small"
            label="Rechercher un billet"
            disabled={!billets?.length>0}
            sx={{width:'60%', minWidth:'300px'}}
            value={search_input}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <Search />
                    </InputAdornment>
                ),
            }}
            onChange={(event)=> setSearch(event.target.value)}
            />
            <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='subtitle1'>Filtres: </Typography>
                <TextField select
                size="small" sx={{ width:'100%', minWidth:'250px', maxWidth:'500px'}}
                label="Filtre" value={filter}
                onChange={(e)=>setFilter(e.target.value)}
                >
                    <MenuItem value='payé'>
                        <Typography>Payé</Typography> 
                    </MenuItem>
                    <MenuItem value='non payé'>
                        <Typography>Non payé</Typography> 
                    </MenuItem>
                    <MenuItem value='transition'>
                        <Typography>Transition</Typography> 
                    </MenuItem>
                    <MenuItem value=''>
                        <Typography>Indifférent</Typography> 
                    </MenuItem>
                </TextField>
            </Stack>
        </Box>
        <Stack direction='row' spacing={10} sx={{marginTop:'25px'}}>
            <Typography variant='subtitle1'>Taux de réservation: {((rotations.reduce((tt,current)=>tt+current.places-current.dispo_places,0)/(rotations.reduce((tt, current)=>tt+current.places,0)>0?rotations.reduce((tt, current)=>tt+current.places,0):1))*100).toFixed(2)}%</Typography>
            <Typography variant='subtitle1'>Places disponibles: {rotations.reduce((tt, current)=>tt+current.dispo_places,0)}</Typography>
            <Typography variant='subtitle1'>Places réservés: {rotations.reduce((tt, current)=>tt+current.places-current.dispo_places,0)}</Typography>
            <><Typography variant='subtitle1' color='rose.main'>Places réservables: {rotations.reduce((tt, current)=>tt+current.places,0)}</Typography></>
        </Stack>

        {billets.length>0?<SorTableCheckable data={billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt))} table={table} selectItems={selected} setItems={setSelect} rowsPerPageOptions={[50,100,300]} rowsPerPage={100} />
        :rotations.filter(rtn=>!rtn.canceled)<=0&&rotations.length>0&&<Typography fontSize={32} fontWeight={500} color='red.main' align='center' marginTop='80px'>Annulé</Typography>}
        
        <Dialog open={open} onClose={()=>manage_dialog(false, '')} fullScreen={action==='add'||(action!=='edit'&&action.includes('edit'))} fullWidth maxWidth={['delete', 'edit'].includes(action) ? 'xs' : action==='pdf'? 'md': 'sm'} sx={{minWidth:'500px'}}>
            {action!=='pdf'&&<IconButton onClick={()=> manage_dialog(false, '')} sx={{position:'absolute', right:'10px', top:'10px'}} ><Close/></IconButton>}
            {action==='add' && <ReservationForm addRsv={blt=>setBillets(old=> old.concat(blt.filter(b=>moment.utc(b.rotation.date_depart).format('yyyy-MM-DD')===date)))} date={date} port={port} ports={ports} close={()=> manage_dialog(false, '')}/>}
            {/* change rotation billet */}
            {action==='edit' && billet && <Box display='flex' flexDirection='column' marginY='30px'>
                <Typography variant='h5' align='center' marginBottom='20px'>Modifier:</Typography>
                <Stack direction='row' spacing={1} sx={{alignSelf:'center'}}>
                    {(!["en attente", "remboursée"].includes(billet.reservation?.pay_status))&&!billet.isScanned&&<Button variant='outlined' onClick={()=>setAction('edit billet')}>Le billet</Button>}    
                    <Button variant='contained' onClick={()=>setAction('edit reservation')}>La réservation</Button>
                </Stack>    
            </Box>}
            {action==='edit billet' && <BilletForm close={()=> manage_dialog(false, '')} addRsv={edited=> setBillets(old=> { 
                if (date===moment.utc(edited.rotation.date_depart).format('yyyy-MM-DD')) {
                    old[old.findIndex(b=> b._id===edited._id)]=edited
                    return old;
                } else return old.filter(blt=>blt._id!==edited._id)})} 
            billet={billet} />}
            {action==='edit reservation' && <ReservationForm close={()=> manage_dialog(false, '')} 
                addRsv={edited=> setBillets(old=> old.filter(bl=>!edited.some(ed=>ed._id===bl._id)).concat(edited.filter(b=>moment.utc(b.rotation.date_depart).format('yyyy-MM-DD')===date)))} 
                edit={true} billet={billet} 
                passagers={billets.filter(blt=>blt.reservation._id===billet.reservation._id).map(blt=>blt.passager)} />}
            {action==='valid'&& <Box display='flex' flexDirection='column' margin='30px 20px 20px 20px'>
                <Typography variant='subtitle1'>Êtes-vous sûr de vouloir {billet.isScanned ? 'annuler la validation du' : 'valider le'} billet n°{billet.num}?</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={handleScan}>Oui</Button>
                    <Button variant='contained' onClick={()=>manage_dialog(false, '')}>Non</Button>
                </Stack>
            </Box>}
            {action==='info' && billet && <Box display='flex' flexDirection='column' marginTop='20px' padding='20px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails billet</Typography>
                <Box display='flex' justifyContent='space-between'>
                    <Box>
                        <Typography variant='subtitle1'>N° billet: {billet.num}</Typography>
                        <Typography variant='subtitle1'>Date départ: {moment.utc(billet.rotation.date_depart).format('DD/MM/yyyy')}</Typography>
                        <Typography variant='subtitle1' textTransform='uppercase'>Départ: {billet.rotation.from.nom}</Typography>
                    </Box>
                    <Box>
                        <Typography variant='subtitle1' textTransform='capitalize'>Passager: {billet.passager.nom+' '+billet.passager.prenom}</Typography>
                        <Typography variant='subtitle1'>Heure départ: {billet.rotation.heure_depart.map(n=>String(n).padStart(2,'0')).join('h')}</Typography>
                        <Typography variant='subtitle1' textTransform='uppercase'>Arrivée: {billet.rotation.to.nom}</Typography>
                    </Box>
                </Box>
                <Divider variant='middle' sx={{marginY:'20px', marginX:'15%'}}/>

                <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails réservation</Typography>
                <Typography variant='subtitle1'>N° réservation: {billet.reservation.num}</Typography>
                <Typography variant='subtitle1'>Type de réservation: {billet.reservation.isA_R?'Aller/Retour': 'Aller Simple'}</Typography>
                <Typography variant='subtitle1' textTransform='capitalize'>Passagers: {billet.reservation.passagers.join(', ')}</Typography>
                <Typography variant='subtitle1'>Statut paiement: {billet.reservation.pay_status}</Typography>
                {billet.reservation.promo_code!==''&&<Typography variant='subtitle1'>Code promo: {billet.reservation.promo_code}</Typography>}
                <Typography variant="subtitle1">Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€</Typography>
                {billet.reservation.payments.length>0&&<Typography variant='subtitle1'>Paiements:</Typography>}
                {billet.reservation.payments.map((pay, index)=>
                    <Typography key={index} variant='subtitle1' marginLeft='10px'>- {pay.montant.toFixed(2)}€ en {pay.mode} le {moment.utc(pay.date).format('DD/MM/yyyy')} {pay.ref!==''?`référencé ${pay.ref}`:''}</Typography>
                )}
                <Typography variant="subtitle1">Reste à payer: {billet.reservation?.rest_to_pay.toFixed(2)}€</Typography>
                {billet.reservation?.updates>1&&<Typography variant="subtitle1">Frais de modification: {((billet?.reservation?.updates-1)*5).toFixed(2)}€</Typography>}
                {billet.reservation?.rest_to_pay<=0&&<Typography variant="subtitle1">N° facture: {billet?.reservation?.num_facture}</Typography>}
                {billet.reservation?.commentaire!==''&&<Typography variant="subtitle1">Commentaire: {billet?.reservation?.commentaire}</Typography>}
                {billet.reservation?.where!=='web'&&<Typography variant="subtitle1">Réservation enregistrée par: <span style={{textTransform:'capitalize'}}>{billet?.reservation?.by}</span></Typography>}
                </Box>}
            {action==='delete' && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Supprimer la réservation n°{billet.reservation.num}</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                    <Button variant='contained' onClick={handleDelete}>Supprimer la réservation</Button>
                </Stack>
            </Box>}
            {action==='pdf' && pdf && <embed src={pdf} width='100%' height='600px' type="application/pdf" />}
            {action==='transition' &&<Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Valider la transition de {selected.length} billet(s)</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                    <Button variant='contained' onClick={handleTransition}>Valider</Button>
                </Stack>
            </Box>}
            {action==='refund' && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Rembourser la réservation {billet.reservation.num}</Typography>
                <Typography variant='subtitle1' marginBottom='10px'>Total paiement (à rembourser): {billet.reservation?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                    <Button variant='contained' onClick={handleRefund}>Rembourser</Button>
                </Stack>
            </Box>}
            {action==='bascule' && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Basculer la réservation {billet.reservation.num} de {billet.reservation.passagers.length} passager(s)</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                    <Button variant='contained' onClick={handleSwap}>Basculer</Button>
                </Stack>
            </Box>}
            {action==='delete many' && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Supprimer les {billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)).length} billets</Typography>
                <Stack alignSelf='end' direction='row' spacing={1}>
                    <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                    <Button variant='contained' onClick={handleDeleteMany}>Supprimer</Button>
                </Stack>
            </Box>}
            {action==='pay status' && billet && <Box display='flex' flexDirection='column' margin='30px'>
                <Typography variant='h3' textAlign='center' marginBottom='20px'>Paiement</Typography>
                <Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                    Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€
                </Typography>
                <Typography variant="subtitle1">Déjà payé : {billet.reservation?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
                <Typography variant="subtitle1" color='red'>Reste à payer: {billet.reservation?.rest_to_pay.toFixed(2)}€</Typography>
                {billet.reservation?.updates>1&&<Typography variant="subtitle1" style={{margin:'0 20px 20px 0'}}>
                    Frais de modification: {((billet?.reservation?.updates-1)*5).toFixed(2)}€
                </Typography>}
            </Box>}
            </Dialog>

        <Backdrop open={loading} ><CircularProgress/></Backdrop>          
      </Box>
    );
}