import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, TableCell, CircularProgress, Backdrop, Dialog, IconButton, Box, DialogTitle, DialogActions, Snackbar} from '@mui/material';
import { Context } from '../App';
import { Add, Close, Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { API } from '../asset/conf';
import ShipForm from './ship-form';
import SimpleTable from './simple-table';
import Rotations from './rotations-ship';

export default function ShipsTable() {
    const { updateConnect } = useContext(Context)
    const [loading, setLoad] = useState(true)
    const [open, setOpen] = useState(false)
    const [sb_open, setSbOpen] = useState(false)
    const [msg, setMsg] = useState('')
    const [d_action, SetAction] = useState('')
    const [ships, setShips] = useState([])
    const [ship, setShip] = useState()

    const table =[
        {header:'Nom', row:(row, index)=> <TableCell key={index} align='center' sx={{width:'50%'}}>{row.nom}</TableCell>},
        {header:'Matricule', row:(row, index)=> <TableCell key={index} align='center' sx={{width:'50%'}}>{row.matricul}</TableCell>},
        {header:'Places', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.places}</TableCell>},
        {header:'Places PMR', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.places_pmr}</TableCell>},
        {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'120px'}}>
            <IconButton aria-label="modifier" onClick={()=>{setShip(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>
            <IconButton aria-label="informations complémentaires" onClick={()=>{setShip(row); manage_dialog(true, 'info')}}><InfoOutlined fontSize="small"/></IconButton>
            {ships.length>1&&<IconButton aria-label="supprimer" onClick={()=>{setShip(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>}
        </TableCell>},
    ]

    async function manage_dialog(state, action) {
        setOpen(state)
        SetAction(action)
        if (!state) {
            setShip()
        }
    }

    function handleDelete() {
        setLoad(true)
        fetch(API.concat(`/bateaux/${ship._id}/delete`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setShips(old => old.filter(shp=> shp._id !== ship._id))
                    setLoad(false)
                    manage_dialog(false,'')
                    setMsg('Bateau supprimé avec succès'); setSbOpen(true)
                }
            }
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSbOpen(false); setMsg('')
    };

    useEffect(()=>{
        function getShips() {
            fetch(API.concat('/bateaux'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setShips(json)
                        setLoad(false)
                    }
                }
            })
        }

        getShips()
    },[updateConnect])


    return (
      <Box sx={{width:'100%', display:'flex', flex:1, flexDirection:'column', alignItems:'center'}}>
        <Typography variant='subtitle2' textTransform='uppercase' sx={{marginTop:'25px'}}>gestion des bateaux</Typography>
        <Button variant='contained' sx={{alignSelf:'flex-end'}} onClick={()=> manage_dialog(true, 'add')} startIcon={<Add />}>Ajouter</Button>
        {ships?.length>0&&<SimpleTable table={table} data={ships} />}

        <Dialog open={open} fullWidth maxWidth={d_action==='info' ? 'xl' : 'sm'} PaperProps={{style: {minWidth: '500px', paddingTop:'35px'}}} >
            <IconButton onClick={()=> manage_dialog(false, '')} sx={{position:'absolute', right:'10px', top:0}} ><Close/></IconButton>
            {d_action==='add'&&<ShipForm addShip={(ship)=> setShips(old=> [...old, ship])} close={()=> manage_dialog(false,'')}/>}
            {d_action==='edit'&&<ShipForm ship={ship} edit={true} addShip={(edited)=> setShips(old=> {
                const nship=[...old]; nship[nship.indexOf(ship)]=edited; return nship
            })} close={()=> manage_dialog(false,'')}/>}
            {d_action==='info'&&<Rotations ship={ship} close={()=> manage_dialog(false,'')} setMsg={setMsg} setOpen={setSbOpen}/>}
            {d_action==='delete'&&<>
                <DialogTitle sx={{marginInline:'30px'}}>Supprimer {ship.nom}</DialogTitle>
                <DialogActions sx={{marginBottom:'10px'}}>
                    <Button variant='contained' color="error" onClick={handleDelete}>Comfimer</Button>
                    <Button variant='contained' color='secondary' onClick={()=> manage_dialog(false,'')}>Annuler</Button>
                </DialogActions>
            </>}
        </Dialog>

        <Snackbar
        open={sb_open}
        autoHideDuration={3500}
        onClose={handleClose}
        message={msg}
        />
        <Backdrop open={loading} ><CircularProgress/></Backdrop>          
      </Box>
    );
}